import HalfBanner from "../components/HalfBanner";
import ChangeIMG from "../helpers/ChangeIMG";
import ChangeSVG from "../helpers/ChangeSVG";
import { Helmet } from 'react-helmet';


const Referanslar = () => {
    return (
        <main>
            <Helmet>
                <title>Güzeltepe - Referanslar</title>
                <meta name="description" content="Güzeltepe, inşaat ve yapı malzemeleri sektöründe birçok başarılı projeye imza atmıştır. Referanslarımız arasında yer alan projeler, betonarme, çelik konstrüksiyon, izolasyon, kaplama ve döşeme gibi birçok alanda başarıyla tamamlanmıştır. Referanslarımızı inceleyerek kaliteli ve güvenilir hizmetimizi görebilirsiniz." />
            </Helmet>

            <HalfBanner page={'REFERANSLARIMIZ'} />

            <section className="pt-10">
                <article className="container flex flex-col justify-between gap-10 lg:items-center lg:flex-row lg:gap-36">
                    <div className="relative">
                        <h2 className="text-3xl font-black">Referanslar</h2>
                        <div className="absolute -bottom-3 start-0 end-0">
                            <ChangeSVG name={'guzelobj'} width={200} height={12} />
                        </div>
                    </div>
                    <p className="flex mt-6">Yüksek kaliteli ürünlerimiz güçlü yapısı ve dayanıklılığı ile endüstriyel ve inşaat sektöründeki talepleri karşılıyor. Ürün gruplarımızla projelerinize sağlam ve güvenilir temeller oluşturuyoruz.</p>
                </article>

                <article className="container pt-10 pb-20">
                    <div className="grid grid-cols-2 gap-5 lg:grid-cols-4">
                        <div className="logoCard">
                            <ChangeIMG page='referanslar' name='card-yucel-boru' alt='Yücel Boru' rounded='md' />
                        </div>
                        <div className="logoCard">
                            <ChangeIMG page='referanslar' name='card-kroman-celik' alt='Yücel Boru' rounded='md' />
                        </div>
                        <div className="logoCard">
                            <ChangeIMG page='referanslar' name='card-rzk-celik' alt='Yücel Boru' rounded='md' />
                        </div>
                        <div className="logoCard">
                            <ChangeIMG page='referanslar' name='card-cayirova-boru' alt='Yücel Boru' rounded='md' />
                        </div>
                        <div className="logoCard">
                            <ChangeIMG page='referanslar' name='card-ayes' alt='Yücel Boru' rounded='md' />
                        </div>
                        <div className="logoCard">
                            <ChangeIMG page='referanslar' name='card-sonmezler-metal' alt='Yücel Boru' rounded='md' />
                        </div>
                        <div className="logoCard">
                            <ChangeIMG page='referanslar' name='card-tos-celik' alt='Yücel Boru' rounded='md' />
                        </div>
                        <div className="logoCard">
                            <ChangeIMG page='referanslar' name='card-lider' alt='Yücel Boru' rounded='md' />
                        </div>
                    </div>
                </article>
            </section>

        </main>
    )
}

export default Referanslar;