import ChangeSVG from "../helpers/ChangeSVG";
import ChangeIMG from '../helpers/ChangeIMG';

const UserFooter = () => {

    return (
        <footer className="bg-[#0C0C0C] text-white relative z-10">
            <ChangeIMG page='anasayfa' name='footer' height='footer' />
            <div className="absolute top-0 bottom-0 end-0 start-0">
                <article className="container pt-20 pb-8">
                    <div className="flex flex-col md:flex-row">
                        <div className="basis-3/12">
                            <a href="/" className="Logo">
                                <ChangeSVG name='footerlogo' width={172} height={120} />
                            </a>
                            <p className="text-sm font-medium my-9">Güzeltepe İnşaat Ve Yapı Malzemeleri Sanayi Ticaret Limited Şirketi</p>
                            <p className="text-xs opacity-75">Güzeltepe Metal, demir çelik sektöründe uzun yıllardan beri hizmet vermekte, güçlü markaların yetkili bayiliğini yürütmektedir.</p>
                        </div>
                        <div className="flex flex-col justify-between gap-12 mt-12 md:flex-row lg:pl-36 basis-9/12 lg:mt-0 lg:gap-0">
                            <div className="basis-2/12">
                                <span className="text-lg font-bold">Bağlantılarımız</span>
                                <ul className="flex flex-col gap-4 mt-8 text-sm">
                                    <li className="duration-300 opacity-50 hover:opacity-100"><a href="/">Anasayfa</a></li>
                                    <li className="duration-300 opacity-50 hover:opacity-100"><a href="/hakkimizda">Hakkımızda</a></li>
                                    <li className="duration-300 opacity-50 hover:opacity-100"><a href="/urunlerimiz/sac-cesitleri">Ürünlerimiz</a></li>
                                    <li className="duration-300 opacity-50 hover:opacity-100"><a href="/referanslar">Referanslar</a></li>
                                    <li className="duration-300 opacity-50 hover:opacity-100"><a href="/blog">Blog</a></li>
                                    <li className="duration-300 opacity-50 hover:opacity-100"><a href="/iletisim">İletişim</a></li>
                                </ul>
                            </div>
                            <div className="basis-2/12">
                                <span className="text-lg font-bold">Ürünlerimiz</span>
                                <ul className="flex flex-col gap-4 mt-8 text-sm">
                                    <li className="duration-300 opacity-50 hover:opacity-100"><a href="/urunlerimiz/sac-cesitleri">Sac Çeşitleri</a></li>
                                    <li className="duration-300 opacity-50 hover:opacity-100"><a href="/urunlerimiz/profil-borular">Yapı Profilleri</a></li>
                                    <li className="duration-300 opacity-50 hover:opacity-100"><a href="/urunlerimiz/panel-cesitleri">Panel Çeşitleri</a></li>
                                    <li className="duration-300 opacity-50 hover:opacity-100"><a href="/urunlerimiz/profiller">Boru Çeşitleri</a></li>
                                    <li className="duration-300 opacity-50 hover:opacity-100"><a href="/urunlerimiz/diger">Diğer Ürünler</a></li>
                                </ul>
                            </div>
                            <div className="text-xs basis-4/12">
                                <span className="text-lg font-bold">Bize Ulaşın</span>
                                <p className="mt-8">Abdiipekçi Mah. Ethempaşa Cad. 1533 Sok. No: 2 Darıca/ Kocaeli</p>
                                <div className="flex items-center gap-3 mt-7">
                                    <ChangeSVG name='phone' width={16} height={16} color='#fff' />
                                    <a href="https://wa.me/+905076424763">+90 507 642 47 63  |  0 262 655 54 85</a>
                                </div>
                                <div className="flex items-center gap-3 mt-4">
                                    <ChangeSVG name='fax' width={16} height={16} color='#fff' />
                                    <a href="/" className="mb-1">0 262 655 51 05</a>
                                </div>
                                <div onClick={(e) => { window.location.href = 'mailto:info@guzeltepemetal.com'; }} className="flex items-center gap-3 mt-4 cursor-pointer">
                                    <ChangeSVG name='envelope' width={16} height={16} color='#fff' />
                                    <div className="mb-1">info@guzeltepemetal.com</div>
                                </div>

                                <span className="flex mt-10 text-lg font-bold">Sosyal Medya</span>
                                <div className="mt-3">
                                    <ul className="flex items-center gap-3">
                                        <li><a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/guzeltepe.metal/ "><ChangeSVG name='instagram' width={28} height={28} /></a></li>
                                        {/* <li><a href="/"><ChangeSVG name='instagram' width={28} height={28} /></a></li>
                                        <li><a href="/"><ChangeSVG name='facebook' width={28} height={28} /></a></li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col justify-between mt-16 opacity-25 lg:items-center md:flex-row pe-14">
                        <div>© Copyright 2024 Güzeltepe Metal Tüm Hakları Saklıdır.</div>
                        <span className="mt-4 lg:mt-0">Time Yazılım Teknoloji A.Ş</span>
                    </div>
                </article>
            </div>
        </footer>
    )
}

export default UserFooter;