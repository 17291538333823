
const ChangeIMG = ({ page, name, alt, rounded = null, height = null, width = null, }) => {

    const pathImage = require(`../assets/images/${page}/${name}.png`);

    if (!pathImage) {
        return <div>Hata!</div>;
    }

    return (
        <img className={`
        ${height === 'banner' ? 'h-[400px] lg:h-full'
                : height === 'footer' ? 'h-[1530px] md:h-[700px] lg:h-full'
                    : height === 'hizmetdetay' ? 'h-[1450px] lg:h-full'
                        : height === 'section' ? 'h-[875px] lg:h-full'
                            : height === 'full' ? 'h-full' : ''}        
                            
        ${width === 'auto' ? 'w-auto' : 'w-full'} 
        ${rounded === 'md' ? 'rounded-md' : rounded === 'xl' ? 'rounded-xl' : ''} object-cover`} src={pathImage} alt={alt} title={alt} />
    );

}

export default ChangeIMG;