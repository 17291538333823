import hizmetBanner from "../assets/images/anasayfa/hizmetBanner.png";
import kaliteBanner from "../assets/images/anasayfa/kaliteBanner.png";
import stokBanner from "../assets/images/anasayfa/stokBanner.png";
import teslimatBanner from "../assets/images/anasayfa/teslimatBanner.png";

import ChangeSVG from "../helpers/ChangeSVG";


import { Navigation, Pagination, Scrollbar, Autoplay } from 'swiper/modules';
import SwiperSlider from '../components/SwiperSlider';
import ChangeIMG from '../helpers/ChangeIMG';

import { Helmet } from 'react-helmet';

const Home = () => {
    return (
        <main>
            <Helmet>
                <title>Güzeltepe - İnşaat ve Yapı Malzemeleri</title>
                <meta name="description" content="Güzeltepe, inşaat ve yapı malzemeleri alanında geniş ürün yelpazesi ve uzman kadrosuyla hizmet vermektedir. İnşaat malzemeleri, yapı malzemeleri, dekorasyon ürünleri ve daha fazlası için bizi ziyaret edin." />
            </Helmet>

            <section>
                <article className="relative">
                    <SwiperSlider className="z-20 py-[100px]"
                        modules={[Navigation, Autoplay]} slideView={1} loop={true}
                        initialSlide={1} delay={5000} speed={10000}
                        slides={[
                            <div>
                                <img className="object-cover w-full h-[calc(100vh-6.5rem)] lg:h-[calc(100vh-16rem)]" src={teslimatBanner} alt="" />
                                <div className="absolute top-0 bottom-0 z-20 w-full h-full end-0 start-0">
                                    <div className="container flex items-center justify-start w-full h-full text-white">
                                        <div className="flex flex-col w-full">
                                            <div className="flex mb-24 md:mb-0">
                                                <div className="lg:basis-5/12">
                                                    {/* <span className="text-sm opacity-25">GENİŞ ÜRÜN YELPAZESİ VE GÜÇLÜ STOKLAR</span> */}
                                                    <h1 className="mt-5 text-3xl font-black mb-9"><span className="text-[#D22027]">ZAMANINDA TESLİMAT /</span> GÜVENİLİR HİZMET</h1>
                                                    <p className="opacity-25">Güçlü lojistik ağımız ve deneyimli ekip arkadaşlarımızla, siparişlerinizi zamanında ve eksiksiz bir şekilde teslim etmek için sürekli olarak çalışıyoruz.</p>
                                                    <div className="flex items-center gap-4 mt-8 experience lg:gap-9">
                                                        <div className="flex items-center gap-4 text-[#E0E0E0]">
                                                            <ChangeSVG name={'deneyim'} width={48} height={48} color='#E0E0E0' />
                                                            <div className="flex flex-col gap-1 mt-1">
                                                                <span className="text-sm">Yıllık Deneyim</span>
                                                                <span className="text-xl font-extrabold">+30</span>
                                                            </div>
                                                        </div>
                                                        <div className="flex items-center gap-4 text-[#E0E0E0]">
                                                            <ChangeSVG name={'products'} width={48} height={48} color='#E0E0E0' />
                                                            <div className="flex flex-col gap-1 mt-1">
                                                                <span className="text-sm">Ürün Çeşidimiz</span>
                                                                <span className="text-xl font-extrabold">+150</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="absolute top-0 bottom-0 z-10 w-full h-full end-0 start-0">
                                    <div className="flex w-full h-full">
                                        <div className="w-full h-full lg:basis-5/12">
                                            <div className="w-full h-full bg-black bg-opacity-40 lg:bg-opacity-65 bgCard backdrop-blur-sm">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>,
                            <div>
                                <img className="object-cover w-full h-[calc(100vh-6.5rem)] lg:h-[calc(100vh-16rem)]" src={hizmetBanner} alt="" />
                                <div className="absolute top-0 bottom-0 z-20 w-full h-full end-0 start-0">
                                    <div className="container flex items-center justify-start w-full h-full text-white">
                                        <div className="flex flex-col w-full">
                                            <div className="flex mb-24 md:mb-0">
                                                <div className="lg:basis-5/12">
                                                    {/* <span className="text-sm opacity-25">GENİŞ ÜRÜN YELPAZESİ VE GÜÇLÜ STOKLAR</span> */}
                                                    <h1 className="mt-5 text-3xl font-black mb-9"><p className="text-[#D22027]">GENİŞ ÜRÜN YELPAZESİ /</p> <p>PROFESYONEL HİZMET</p></h1>
                                                    <p className="opacity-25">Sac, Profil , Demir- Çelik sektöründe hizmet vermekteyiz. Sunduğumuz kaliteli ürünler ve müşteri memnuniyeti odaklı çözümlerimizle, sektörde güvenilir firma olarak çalışmaktayız. </p>
                                                    <div className="flex items-center gap-4 mt-8 experience lg:gap-9">
                                                        <div className="flex items-center gap-4 text-[#E0E0E0]">
                                                            <ChangeSVG name={'deneyim'} width={48} height={48} color='#E0E0E0' />
                                                            <div className="flex flex-col gap-1 mt-1">
                                                                <span className="text-sm">Yıllık Deneyim</span>
                                                                <span className="text-xl font-extrabold">+30</span>
                                                            </div>
                                                        </div>
                                                        <div className="flex items-center gap-4 text-[#E0E0E0]">
                                                            <ChangeSVG name={'products'} width={48} height={48} color='#E0E0E0' />
                                                            <div className="flex flex-col gap-1 mt-1">
                                                                <span className="text-sm">Ürün Çeşidimiz</span>
                                                                <span className="text-xl font-extrabold">+150</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="absolute top-0 bottom-0 z-10 w-full h-full end-0 start-0">
                                    <div className="flex w-full h-full">
                                        <div className="w-full h-full lg:basis-5/12">
                                            <div className="w-full h-full bg-black bg-opacity-40 lg:bg-opacity-65 bgCard backdrop-blur-sm">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>,
                            <div>
                                <img className="object-cover w-full h-[calc(100vh-6.5rem)] lg:h-[calc(100vh-16rem)]" src={kaliteBanner} alt="" />
                                <div className="absolute top-0 bottom-0 z-20 w-full h-full end-0 start-0">
                                    <div className="container flex items-center justify-start w-full h-full text-white">
                                        <div className="flex flex-col w-full">
                                            <div className="flex mb-24 md:mb-0">
                                                <div className="lg:basis-5/12">
                                                    {/* <span className="text-sm opacity-25">GENİŞ ÜRÜN YELPAZESİ VE GÜÇLÜ STOKLAR</span> */}
                                                    <h1 className="mt-5 text-3xl font-black mb-9"><p className="text-[#D22027]">YÜKSEK KALİTE  /</p> <p>GÜVENİLİR ÇÖZÜM</p></h1>
                                                    <p className="opacity-25">Kaliteden ödün vermeden güvenilir çözümler sunmaya devam ediyoruz. Müşterilerimizin beklentilerini karşılamak ve işlerini kolaylaştırmak için her zaman en iyisini hedefliyoruz.</p>
                                                    <div className="flex items-center gap-4 mt-8 experience lg:gap-9">
                                                        <div className="flex items-center gap-4 text-[#E0E0E0]">
                                                            <ChangeSVG name={'deneyim'} width={48} height={48} color='#E0E0E0' />
                                                            <div className="flex flex-col gap-1 mt-1">
                                                                <span className="text-sm">Yıllık Deneyim</span>
                                                                <span className="text-xl font-extrabold">+30</span>
                                                            </div>
                                                        </div>
                                                        <div className="flex items-center gap-4 text-[#E0E0E0]">
                                                            <ChangeSVG name={'products'} width={48} height={48} color='#E0E0E0' />
                                                            <div className="flex flex-col gap-1 mt-1">
                                                                <span className="text-sm">Ürün Çeşidimiz</span>
                                                                <span className="text-xl font-extrabold">+150</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="absolute top-0 bottom-0 z-10 w-full h-full end-0 start-0">
                                    <div className="flex w-full h-full">
                                        <div className="w-full h-full lg:basis-5/12">
                                            <div className="w-full h-full bg-black bg-opacity-40 lg:bg-opacity-65 bgCard backdrop-blur-sm">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>,
                            <div>
                                <img className="object-cover w-full h-[calc(100vh-6.5rem)] lg:h-[calc(100vh-16rem)]" src={stokBanner} alt="" />
                                <div className="absolute top-0 bottom-0 z-20 w-full h-full end-0 start-0">
                                    <div className="container flex items-center justify-start w-full h-full text-white">
                                        <div className="flex flex-col w-full">
                                            <div className="flex mb-24 md:mb-0">
                                                <div className="lg:basis-5/12">
                                                    {/* <span className="text-sm opacity-25">GENİŞ ÜRÜN YELPAZESİ VE GÜÇLÜ STOKLAR</span> */}
                                                    <h1 className="mt-5 text-3xl font-black mb-9"><p className="text-[#D22027]">GÜÇLÜ STOKLARIMIZLA /</p> <p>HER AN YANINIZDAYIZ</p></h1>
                                                    <p className="opacity-25">Geniş ürün yelpazemiz ve güçlü tedarik ağımız sayesinde, müşterilerimizin taleplerini anında karşılayabilmekte ve iş süreçlerini kesintisiz bir şekilde ilerletebilmekteyiz.</p>
                                                    <div className="flex items-center gap-4 mt-8 experience lg:gap-9">
                                                        <div className="flex items-center gap-4 text-[#E0E0E0]">
                                                            <ChangeSVG name={'deneyim'} width={48} height={48} color='#E0E0E0' />
                                                            <div className="flex flex-col gap-1 mt-1">
                                                                <span className="text-sm">Yıllık Deneyim</span>
                                                                <span className="text-xl font-extrabold">+30</span>
                                                            </div>
                                                        </div>
                                                        <div className="flex items-center gap-4 text-[#E0E0E0]">
                                                            <ChangeSVG name={'products'} width={48} height={48} color='#E0E0E0' />
                                                            <div className="flex flex-col gap-1 mt-1">
                                                                <span className="text-sm">Ürün Çeşidimiz</span>
                                                                <span className="text-xl font-extrabold">+150</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="absolute top-0 bottom-0 z-10 w-full h-full end-0 start-0">
                                    <div className="flex w-full h-full">
                                        <div className="w-full h-full lg:basis-5/12">
                                            <div className="w-full h-full bg-black bg-opacity-40 lg:bg-opacity-65 bgCard backdrop-blur-sm">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>,
                        ]}
                    />

                    <div className="absolute bottom-0 z-20 hidden w-full h-full end-0 start-0 md:block">
                        <div className="container w-full h-full text-white">
                            <div className="flex items-end justify-end w-full h-full">
                                <div className="flex items-end justify-end w-full">
                                    <div className="w-7/12">
                                        <div className="relative">
                                            <SwiperSlider className="z-20 py-[50px]"
                                                modules={[Navigation, Pagination, Scrollbar, Autoplay]}
                                                spaceBetween={36} slideView={3} loop={true} allowTouchMove={false}
                                                initialSlide={1} delay={5000} speed={10000} padding={50}
                                                slides={[
                                                    <div className="flex flex-col items-center gap-3">
                                                        <ChangeIMG page={'anasayfa'} name={'hizmet'} rounded={'xl'} />
                                                        <span className="text-sm font-bold">Hizmet</span>
                                                    </div>,
                                                    <div className="flex flex-col items-center gap-3 ">
                                                        <ChangeIMG page={'anasayfa'} name={'bannerproduct01'} rounded={'xl'} />
                                                        <span className="text-sm font-bold">Kalite</span>
                                                    </div>,
                                                    <div className="flex flex-col items-center gap-3">
                                                        <ChangeIMG page={'anasayfa'} name={'bannerproduct03'} rounded={'xl'} />
                                                        <span className="text-sm font-bold">Stok</span>
                                                    </div>,
                                                    <div className="flex flex-col items-center gap-3">
                                                        <ChangeIMG page={'anasayfa'} name={'bannerproduct04'} rounded={'xl'} />
                                                        <span className="text-sm font-bold">Teslimat</span>
                                                    </div>,
                                                ]}
                                            />
                                            <div className="absolute top-0 bottom-0 right-0 z-10 flex items-center lg:-right-20">
                                                <button className="swiper-button-next"> <ChangeSVG className="rotate-90" name="arrowleft" width={20} height={20} color="#fff" /></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>
            </section>

            <section className="relative z-10">
                <article>
                    <div className="bg-[#000] bg-opacity-15 text-black py-2">
                        <div className="container">
                            <h2 className="text-sm italic font-black">YETKİLİ SATICISI OLDUĞUMUZ FİRMALAR</h2>
                        </div>
                    </div>
                </article>
                <article className="container relative mt-6">
                    <SwiperSlider className="z-20"
                        modules={[Navigation, Scrollbar, Autoplay]} prev='.blog-prev' next='.blog-next'
                        spaceBetween={36} slideView={5} loop={true}
                        initialSlide={1} delay={5000} speed={10000}
                        slides={[
                            <div className="p-4 bg-white border border-black rounded-md border-opacity-5">
                                <ChangeIMG page='referanslar' name='colorful-ayes' />
                            </div>,
                            <div className="p-4 bg-white border border-black rounded-md border-opacity-5">
                                <ChangeIMG page='referanslar' name='colorful-cayirova-boru' />
                            </div>,
                            <div className="p-4 bg-white border border-black rounded-md border-opacity-5">
                                <ChangeIMG page='referanslar' name='colorful-kromancelik' />
                            </div>,
                            <div className="p-4 bg-white border border-black rounded-md border-opacity-5">
                                <ChangeIMG page='referanslar' name='colorful-liderlik' />
                            </div>,
                            <div className="p-4 bg-white border border-black rounded-md border-opacity-5">
                                <ChangeIMG page='referanslar' name='colorful-rzkcelik' />
                            </div>,
                            <div className="p-4 bg-white border border-black rounded-md border-opacity-5">
                                <ChangeIMG page='referanslar' name='colorful-sonmezler-metal' />
                            </div>,
                            <div className="p-4 bg-white border border-black rounded-md border-opacity-5">
                                <ChangeIMG page='referanslar' name='colorful-tozcelik' />
                            </div>,
                            <div className="p-4 bg-white border border-black rounded-md border-opacity-5">
                                <ChangeIMG page='referanslar' name='colorful-yucelboru' />
                            </div>,
                        ]}
                    />
                    <div className="absolute bottom-0 z-20 text-white top-16 md:top-3 lg:top-8 right-5 lg:-right-12">
                        <button className="-rotate-90 blog-prev"> <ChangeSVG name="arrowdown" width={16} height={16} color="#fff" /></button>
                    </div>
                    <div className="absolute bottom-0 z-20 text-white top-16 left-5 md:top-3 lg:top-8 lg:-left-12">
                        <button className="rotate-90 blog-next"> <ChangeSVG name="arrowdown" width={16} height={16} color="#fff" /></button>
                    </div>
                </article>
            </section>

            <section className="pt-20">
                <article className="container">
                    <div className="relative">
                        <h2 className="text-3xl font-black">ÜRÜN GRUPLARIMIZ</h2>
                        <div className="absolute hidden bottom-1 start-0 end-0 md:block">
                            <ChangeSVG name={'guzelobj'} width={350} height={12} />
                        </div>
                    </div>
                    <p className="flex mt-14">Yüksek kaliteli ürünlerimiz güçlü yapısı ve dayanıklılığı ile endüstriyel ve inşaat sektöründeki talepleri karşılıyor. Ürün gruplarımızla projelerinize sağlam ve güvenilir temeller oluşturuyoruz.</p>
                </article>
                <article className="container">
                    <div className="grid grid-cols-1 mt-20 md:grid-cols-2 lg:grid-cols-3 gap-9">
                        <a href="/urunlerimiz/sac-cesitleri" className="">
                            <div className="relative overflow-hidden rounded-lg group">
                                <div className="duration-500 group-hover:scale-110">
                                    <ChangeIMG page={'anasayfa'} name={'pr01'} />
                                </div>
                                <div className="absolute top-0 bottom-0 bg-gradient-to-t to-[#00000000] via-[#00000050] from-[#000000] end-0 start-0"></div>
                                <div className="absolute bottom-0 px-5 text-white pb-7 start-0 end-0">
                                    <span className="text-xl font-extrabold">SAC ÇEŞİTLERİ</span>
                                    <p className="mt-3 text-sm opacity-50 line-clamp-2">Geniş bir ürün yelpazemiz ile farklı kalınlıklarda, boyutlarda ve özelliklerdeki sac çeşitlerimizi inceleyin.</p>
                                </div>
                            </div>
                        </a>
                        <a href="/urunlerimiz/profil-borular">
                            <div className="relative overflow-hidden rounded-lg group">
                                <div className="duration-500 group-hover:scale-110">
                                    <ChangeIMG page={'anasayfa'} name={'pr02'} />
                                </div>
                                <div className="absolute top-0 bottom-0 bg-gradient-to-t to-[#00000000] via-[#00000050] from-[#000000] end-0 start-0"></div>
                                <div className="absolute bottom-0 px-5 text-white pb-7 start-0 end-0">
                                    <span className="text-xl font-extrabold ">PROFİL VE BORULAR</span>
                                    <p className="mt-3 text-sm opacity-50 line-clamp-2">Geniş bir boru ve profil seçeneği sunarak modern tasarım ve güçlü yapıların oluşturulmasına katkıda bulunuyoruz.</p>
                                </div>
                            </div>
                        </a>
                        <div>
                            <div className="relative h-full overflow-hidden rounded-lg group">
                                <div className="flex flex-col justify-center h-full">
                                    <div className="flex items-center justify-center w-full">
                                        <ChangeSVG name='tork' width={180} height={180} />
                                    </div>
                                    <p className="mb-12 opacity-50 mt-9 line-clamp-3">Endüstri standartlarını aşan kalite kontrollerinden geçirilmiş, 25'ten fazla ürün çeşidimizle, özel ihtiyaçlarınıza en uygun çözümleri buluyoruz.</p>
                                    <div className="mb-6 lg:mb-0">
                                        <a href="/urunlerimiz/sac-cesitleri" className="p-4 rounded-md text-white bg-[#D22027] font-semibold text-sm">
                                            Tüm Ürünlerimizi Görüntüleyin
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <a href="/urunlerimiz/panel-cesitleri">
                            <div className="relative overflow-hidden rounded-lg group">
                                <div className="duration-500 group-hover:scale-110">
                                    <ChangeIMG page={'anasayfa'} name={'pr03'} />
                                </div>
                                <div className="absolute top-0 bottom-0 bg-gradient-to-t to-[#00000000] via-[#00000050] from-[#000000] end-0 start-0"></div>
                                <div className="absolute bottom-0 px-5 text-white pb-7 start-0 end-0">
                                    <span className="text-xl font-extrabold">PANEL ÇEŞİTLERİ</span>
                                    <p className="mt-3 text-sm opacity-50 line-clamp-2">Enerji verimliliğini maksimize eden panellerimiz, dayanıklı yapısı ile montaj süreçlerini hızlandırır, uzun ömürlü kullanım sunar.</p>
                                </div>
                            </div>
                        </a>
                        <a href="/urunlerimiz/profiller">
                            <div className="relative overflow-hidden rounded-lg group">
                                <div className="duration-500 group-hover:scale-110">
                                    <ChangeIMG page={'anasayfa'} name={'pr04'} />
                                </div>
                                <div className="absolute top-0 bottom-0 bg-gradient-to-t to-[#00000000] via-[#00000050] from-[#000000] end-0 start-0"></div>
                                <div className="absolute bottom-0 px-5 text-white pb-7 start-0 end-0">
                                    <span className="text-xl font-extrabold">NPU-NPI-HEB-HEA-İPE</span>
                                    <p className="mt-3 text-sm opacity-50 line-clamp-2">Yüksek mukavemet ve dayanıklılıkla öne çıkan ürünlerimiz, sıcak olarak haddelenme işlevi görmüş, korozyona karşı dayanıklı bir kullanım sunar.</p>
                                </div>
                            </div>
                        </a>
                        <a href="/urunlerimiz/insaat-demirleri">
                            <div className="relative overflow-hidden rounded-lg group">
                                <div className="duration-500 group-hover:scale-110">
                                    <ChangeIMG page={'anasayfa'} name={'insaat-demirleridi'} />
                                </div>
                                <div className="absolute top-0 bottom-0 bg-gradient-to-t to-[#00000000] via-[#00000050] from-[#000000] end-0 start-0"></div>
                                <div className="absolute bottom-0 px-5 text-white pb-7 start-0 end-0">
                                    <span className="text-xl font-extrabold">İNŞAAT DEMİRLERİ</span>
                                    <p className="mt-3 text-sm opacity-50 line-clamp-2">Hırdavat çeşitleri, boya, dekoratif demir, kesme taşları, elektrot gibi geniş ürün çeşitlerimizi inceleyin ve ihtiyacınıza uygun kaliteli çözümleri keşfedin.</p>
                                </div>
                            </div>
                        </a>
                    </div>
                </article>
            </section>

            <section className="py-16 mt-20 bg-white">
                <article className="container">
                    <span className="text-3xl italic font-black text-[#D22027]">BAŞARILARIMIZ</span>
                    <p className="mt-5">Yüksek kaliteli ürünlerimiz güçlü yapısı ve dayanıklılığı ile endüstriyel ve inşaat sektöründeki talepleri karşılıyor. Ürün gruplarımızla projelerinize sağlam ve güvenilir temeller oluşturuyoruz.</p>
                </article>
                <article className="container py-16">
                    <div className="grid grid-cols-1 gap-6 lg:grid-cols-3">
                        <div className="px-6 py-6 border border-black rounded-md card border-1">
                            <div className="flex items-center justify-center w-full">
                                <ChangeSVG name='deneyim' width={96} height={96} color='#000' />
                            </div>
                            <div className="flex items-center justify-between mb-6 mt-9">
                                <span className="font-bold">Mutlu Müşteri</span>
                                <span className="text-3xl font-black">+ 1000</span>
                            </div>
                            <p className="text-sm opacity-50">Siz değerli müşterilerimizle yaptığımız her işbirliği, ürün ve hizmet kalitemizi daha da geliştirmemize olanak tanıdı.</p>
                        </div>
                        <div className="px-6 py-6 border border-[#D22027] bg-[#D22027] rounded-md text-white card border-1">
                            <div className="flex items-center justify-center w-full">
                                <ChangeSVG name='success' width={106} height={106} color='#fff' />
                            </div>
                            <div className="flex items-center justify-between mb-6 mt-9">
                                <span className="font-bold">Satıcısı Olduğumuz Firmalar</span>
                                <span className="text-3xl font-black">+ 10</span>
                            </div>
                            <p className="text-sm opacity-50">Global ölçekte güçlü markaların bayisi olarak faaliyet gösteriyoruz. Sunduğumuz markalar, sektörde lider  konumundadır.</p>
                        </div>
                        <div className="px-6 py-6 border border-black rounded-md card border-1">
                            <div className="flex items-center justify-center w-full">
                                <ChangeSVG name='products' width={96} height={96} color='#000' />
                            </div>
                            <div className="flex items-center justify-between mb-6 mt-9">
                                <span className="font-bold">Ürün Yelpazesi</span>
                                <span className="text-3xl font-black">+ 450</span>
                            </div>
                            <p className="text-sm opacity-50">Size özel ihtiyaçlarınıza uygun en uygun ürünü bulabilmek için çeşitlilik, kalite odaklı bir yaklaşım benimsiyoruz.</p>
                        </div>
                    </div>
                </article>

                <div className="relative">
                    <article className="container relative z-10">
                        <div className="flex flex-col overflow-hidden md:flex-row rounded-xl">
                            <div className="basis-5/12 ">
                                <div className="relative">
                                    <div className="card bg-[#D22027] bg-opacity-75 text-white py-12 px-4 z-10 relative">
                                        <div className="text-xl font-black">GÜZELTEPE METAL</div>
                                        <p className="opacity-50 mt-9">Güzeltepe Metal olarak, demir çelik sektöründe uzun yıllardan beri hizmet vermekte, güçlü markaların yetkili bayiliğini ve ürün satışını yaptığımız 150'den fazla ürünle sektördeki güçlü konumumuzu sürdürüyoruz.</p>
                                        <p className="opacity-50 mt-7">Firmamız, sağladığı geniş ürün yelpazesi ile müşterilerine çeşitli sektörel ihtiyaçlara yönelik kapsamlı çözümler sunmayı hedeflemekte ve sektördeki gelişmeleri yakından takip ederek kalite standartlarını her zaman en üst seviyede tutmaktadır.</p>
                                        <p className="opacity-50 mt-7">Gelecekte de sektördeki başarımızı sürdürerek müşterilerimizin beklentilerini en iyi şekilde karşılamayı amaçlıyoruz.</p>
                                        <div className="flex justify-end mt-12">
                                            <a className="py-3 bg-white px-7 text-[#D22027] font-bold text-sm" href="/iletisim" >BİZİMLE İLETİŞİME GEÇİN</a>
                                        </div>
                                    </div>
                                    <div className="absolute top-0 bottom-0 z-0 end-0 start-0">
                                        <ChangeIMG page='anasayfa' name='blogbanner' height='full' />
                                    </div>
                                </div>
                            </div>
                            <div className="basis-7/12">
                                <div className="blogs bg-[#222] text-white py-4 px-12 h-full relative">
                                    <span className="text-xl font-extrabold">MEDYADA BİZ</span>
                                    <div className="flex flex-col mt-6 gap-9">
                                    </div>
                                    <div className="flex w-full h-96 lg:h-24">
                                        <div className="relative" >
                                            <SwiperSlider className="z-20 h-full"
                                                modules={[Navigation, Autoplay]} prev=".blog-prev" next=".blog-next"
                                                spaceBetween={36} slideView={3} loop={true}
                                                initialSlide={1} delay={2000} speed={10000} direction='vertical' height={'470px'}
                                                slides={[
                                                    <a href="/blog/profil-turleri" className="flex items-center blog">
                                                        <div className="flex flex-col gap-6 md:flex-row">
                                                            <div className="basis-4/12">
                                                                <ChangeIMG page='blog' name='blog01' rounded='md' />
                                                            </div>
                                                            <div className="basis-8/12">
                                                                <div className="content">
                                                                    <div className="text-lg font-bold blogTitle">Profil Türleri ve Kullanım Alanları</div>
                                                                    <p className="my-4 text-sm">Profiller, endüstride ve inşaat sektöründe geniş bir kullanım alanına sahip...</p>
                                                                    <div className="flex items-center gap-2 opacity-50 date">
                                                                        <ChangeSVG name='date' width={12} height={12} color='#fff' />
                                                                        <span className='text-sm'>12.03.2024</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a>,
                                                    <a href="/blog/sandvic-panel" className="flex items-center blog">
                                                        <div className="flex flex-col gap-6 md:flex-row">
                                                            <div className="basis-4/12">
                                                                <ChangeIMG page='blog' name='blog02' rounded='md' />
                                                            </div>
                                                            <div className="basis-8/12">
                                                                <div className="content">
                                                                    <div className="text-lg font-bold blogTitle">Yapı Sektöründe Sandviç Panel</div>
                                                                    <p className="my-4 text-sm">Sandviç paneller, yapı sektöründe hızlı ve verimli bir şekilde kullanılan...</p>
                                                                    <div className="flex items-center gap-2 opacity-50 date">
                                                                        <ChangeSVG name='date' width={12} height={12} color='#fff' />
                                                                        <span className='text-sm'>12.03.2024</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a>,
                                                    <a href="/blog/hirdavat-ve-demir-celik-bakimi" className="flex items-center blog">
                                                        <div className="flex flex-col gap-6 md:flex-row">
                                                            <div className="basis-4/12">
                                                                <ChangeIMG page='blog' name='blog03' rounded='md' />
                                                            </div>
                                                            <div className="basis-8/12">
                                                                <div className="content">
                                                                    <div className="text-lg font-bold blogTitle">Hırdavat ve Demir-Çelik Bakımı</div>
                                                                    <p className="my-4 text-sm">Hırdavat ve demir-çelik ürünler, evdeki küçük onarımlardan endüstriyel uygulamalara...</p>
                                                                    <div className="flex items-center gap-2 opacity-50 date">
                                                                        <ChangeSVG name='date' width={12} height={12} color='#fff' />
                                                                        <span className='text-sm'>12.03.2024</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a>,
                                                    <a href="/blog/dekoratif-demirler" className="flex items-center blog">
                                                        <div className="flex flex-col gap-6 md:flex-row">
                                                            <div className="basis-4/12">
                                                                <ChangeIMG page='blog' name='blog04' rounded='md' />
                                                            </div>
                                                            <div className="basis-8/12">
                                                                <div className="content">
                                                                    <div className="text-lg font-bold blogTitle">Dekorasyonda Dekoratif Demirler</div>
                                                                    <p className="my-4 text-sm">Dekoratif demir, tasarım ve dekorasyon projelerinde sıklıkla kullanılan çok yönlü bir...</p>
                                                                    <div className="flex items-center gap-2 opacity-50 date">
                                                                        <ChangeSVG name='date' width={12} height={12} color='#fff' />
                                                                        <span className='text-sm'>12.03.2024</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a>,
                                                ]}
                                            />
                                        </div>
                                    </div>
                                    <div className="absolute z-10 text-white top-3 lg:top-5 right-24">
                                        <button className="blog-next"> <ChangeSVG name="arrowdown" width={16} height={16} color="#fff" /></button>
                                    </div>
                                    <div className="absolute z-10 text-white top-3 lg:top-5 right-12">
                                        <button className="rotate-180 blog-prev"> <ChangeSVG name="arrowdown" width={16} height={16} color="#fff" /></button>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </article>
                    <div className="absolute top-0 bottom-0 left-0 z-0">
                        <ChangeSVG name='gzltepe' />
                    </div>
                </div>
            </section>

            <div className="w-full h-[2px] bg-[#D22027]"></div>

        </main>
    )
}

export default Home;