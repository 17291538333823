

import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import ChangeSVG from '../helpers/ChangeSVG';

const UserHeader = () => {

    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(prevState => !prevState);
    };

    return (
        <>

            <div className="bg-[#000] bg-opacity-90 py-3 hidden lg:block">
                <article className="container text-white">
                    <div className="flex items-center justify-between">
                        <div className="flex items-center gap-6">
                            <div onClick={(e) => { window.location.href = 'mailto:info@guzeltepemetal.com'; }} className="flex items-center gap-3 cursor-pointer">
                                <ChangeSVG name='envelope' width={14} height={14} color='#fff' />
                                <div className="mb-1 text-xs">info@guzeltepemetal.com</div>
                            </div>
                            <div className="flex items-center gap-3 text-xs">
                                <ChangeSVG name='phone' width={14} height={14} color='#fff' />
                                <div>
                                    <a href="/">0 262 655 54 85</a>
                                    <a href="https://wa.me/+905076424763" className="mx-2">|  +90 0507 642 47 63</a>
                                </div>
                            </div>
                        </div>
                        <p className="text-xs">Abdiipekçi Mah. Ethempaşa Cad. 1533 Sok. No: 2 Darıca/ Kocaeli</p>
                    </div>
                </article>
            </div>

            <header className={`${isScrolled ? 'fixed left-0 right-0 z-50 scrolled  bg-opacity-95 bg-[#000] py-3' : 'absolute top-0 z-50 start-0 end-0 mt-20'} navbar hidden lg:block`}>
                <div className="container">
                    <div className="flex items-center justify-between">
                        <a href='/' className="logo">
                            <ChangeSVG name={'logo'} width={237} height={55} />
                        </a>
                        <div className="flex items-center gap-12 font-medium text-white">
                            <div>
                                <a className="relative" href='/'>
                                    <span>Anasayfa</span>
                                    {pathname === '/' && <div className="absolute flex items-center justify-center -bottom-4 start-0 end-0">
                                        <div className="h-1 w-full bg-[#D22027] rounded-full"></div>
                                    </div>}
                                </a>
                            </div>
                            <div>
                                <a className="relative" href='/hakkimizda'>
                                    <span>Hakkimizda</span>
                                    {pathname === '/hakkimizda' && <div className="absolute flex items-center justify-center -bottom-4 start-0 end-0">
                                        <div className="h-1 w-full bg-[#D22027] rounded-full"></div>
                                    </div>}
                                </a>
                            </div>
                            <div>
                                <a className="relative" href='/urunlerimiz/profil-borular'>
                                    <span>Ürünlerimiz</span>
                                    {(pathname === '/urunlerimiz/sac-cesitleri' || pathname === '/urunlerimiz/profil-borular' || pathname === '/urunlerimiz/insaat-demirleri' || pathname === '/urunlerimiz/panel-cesitleri' || pathname === '/urunlerimiz/profiller' || pathname === '/urunlerimiz/diger') && (<div className="absolute flex items-center justify-center -bottom-4 start-0 end-0">
                                        <div className="h-1 w-full bg-[#D22027] rounded-full"></div>
                                    </div>)}
                                </a>
                            </div>
                            <div>
                                <a className="relative" href='/referanslar'>
                                    <span>Referanslar</span>
                                    {pathname === '/referanslar' && <div className="absolute flex items-center justify-center -bottom-4 start-0 end-0">
                                        <div className="h-1 w-full bg-[#D22027] rounded-full"></div>
                                    </div>}
                                </a>
                            </div>
                            <div>
                                <a className="relative" href='/blog'>
                                    <span>Blog</span>
                                    {pathname === '/blog' && <div className="absolute flex items-center justify-center -bottom-4 start-0 end-0">
                                        <div className="h-1 w-full bg-[#D22027] rounded-full"></div>
                                    </div>}
                                </a>
                            </div>
                            <div>
                                <a className="relative" href='/iletisim'>
                                    <span>İletişim</span>
                                    {pathname === '/iletisim' && <div className="absolute flex items-center justify-center -bottom-4 start-0 end-0">
                                        <div className="h-1 w-full bg-[#D22027] rounded-full"></div>
                                    </div>}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            {/* Mobile Navbar */}
            <nav className="sticky top-0 z-50 block lg:hidden">
                <div className="relative">
                    <div className="bg-[#000] py-4 px-4">
                        <div className="flex items-center justify-between navHead">
                            <Link to='/'>
                                <ChangeSVG name={'logo'} width={175} height={42} color='#fff' />
                            </Link>
                            <div className="mb-2" onClick={toggleMenu}>
                                <ChangeSVG name={'navMenu'} width={24} height={24} color='#fff' />
                            </div>
                        </div>
                    </div>
                    {isMenuOpen && (
                        <div className="absolute bottom-0 w-full h-screen top-[4.25rem] start-0">
                            <div className="bg-[#070b1f] w-full h-full text-white py-6 px-4">
                                <div className="flex items-center justify-between opacity-50">
                                    <span>Hızlı Erişim Sağlayın</span>
                                    <span>3</span>
                                </div>
                                <div className="flex flex-col gap-4 mt-6">
                                    <Link to='/' onClick={toggleMenu} className="flex items-center justify-between px-2 py-2 duration-300 bg-white rounded-md opacity-75 bg-opacity-10">
                                        <span>Anasayfa</span>
                                        <div className="-rotate-90">
                                            <ChangeSVG name={'arrowdown'} width={16} height={16} color='#fff' />
                                        </div>
                                    </Link>
                                    <Link to='/hakkimizda' onClick={toggleMenu} className="flex items-center justify-between px-2 py-2 duration-300 bg-white rounded-md opacity-75 bg-opacity-10">
                                        <span>Hakkımızda</span>
                                        <div className="-rotate-90">
                                            <ChangeSVG name={'arrowdown'} width={16} height={16} color='#fff' />
                                        </div>
                                    </Link>
                                    <Link to='/urunlerimiz/sac-cesitleri' onClick={toggleMenu} className="flex items-center justify-between px-2 py-2 duration-300 bg-white rounded-md opacity-75 bg-opacity-10">
                                        <span>Ürünler</span>
                                        <div className="-rotate-90">
                                            <ChangeSVG name={'arrowdown'} width={16} height={16} color='#fff' />
                                        </div>
                                    </Link>
                                    <div className="flex items-center justify-between opacity-50 mt-7">
                                        <span>Diğer</span>
                                        <span>3</span>
                                    </div>
                                    <Link to='/referanslar' onClick={toggleMenu} className="flex items-center justify-between px-2 py-2 duration-300 bg-white rounded-md opacity-75 bg-opacity-10">
                                        <span>Referanslar</span>
                                        <div className="-rotate-90">
                                            <ChangeSVG name={'arrowdown'} width={16} height={16} color='#fff' />
                                        </div>
                                    </Link>
                                    <Link to='/blog' onClick={toggleMenu} className="flex items-center justify-between px-2 py-2 duration-300 bg-white rounded-md opacity-75 bg-opacity-10">
                                        <span>Blog</span>
                                        <div className="-rotate-90">
                                            <ChangeSVG name={'arrowdown'} width={16} height={16} color='#fff' />
                                        </div>
                                    </Link>
                                    <Link to='/iletisim' onClick={toggleMenu} className="flex items-center justify-between px-2 py-2 duration-300 bg-white rounded-md opacity-75 bg-opacity-10">
                                        <span>İletişim</span>
                                        <div className="-rotate-90">
                                            <ChangeSVG name={'arrowdown'} width={16} height={16} color='#fff' />
                                        </div>
                                    </Link>
                                    <div className="mt-4">
                                        <ul className="flex items-center justify-start gap-2">
                                            {/* <li><Link target="_blank" to="https://www.linkedin.com/company/time-yaz%C4%B1l%C4%B1m"><ChangeSVG name="colorfulfacebook" width={20} height={20} color="#000" /></Link></li> */}
                                            <li><Link target="_blank" to="https://www.linkedin.com/company/time-yaz%C4%B1l%C4%B1m"><ChangeSVG name="instagram" width={20} height={20} color="#000" /></Link></li>
                                            {/* <li><Link target="_blank" to="https://www.linkedin.com/company/time-yaz%C4%B1l%C4%B1m"><ChangeSVG name="colorfultwitter" width={20} height={20} color="#000" /></Link></li> */}
                                            <li><Link target="_blank" to="https://www.linkedin.com/company/time-yaz%C4%B1l%C4%B1m"><ChangeSVG name="instagram" width={20} height={20} color="#000" /></Link></li>
                                        </ul>
                                    </div>
                                    <span className="text-xs opacity-50">Sosyal Medyadan Bizi Takip Edin!</span>
                                    <div className="mt-8">
                                        <span className="font-bold opacity-25">TIME YAZILIM TEKNOLOJİ A.Ş</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </nav>
        </>
    )
}

export default UserHeader;