const ProductData = [
    {
        category: 'sac-cesitleri',
        title: 'Sac Çeşitleri',
        url:'sac-cesitleri',
        products: [
            {
                productID: 'product01',
                title: 'DKP SAC',
                content: 'DKP sac, "Düşük Karbonlu Soğuk Haddelenmiş Sac"ın kısaltmasıdır. DKP sac, düşük karbon içeriğine sahip olan ve soğuk haddeleme işlemiyle üretilen bir tür sacdır.',
                image: 'dkp-sac',
                imagePage: 'urunlerimiz',
                url: 'dkp-sac',
                subContent:'DKP sac, "Düşük Karbonlu Soğuk Haddelenmiş Sac"ın kısaltmasıdır. DKP sac, düşük karbon içeriğine sahip olan ve soğuk haddeleme işlemiyle üretilen bir tür sacdır. Bu işlem, sıcak haddeleme işleminden farklı olarak, sac levhalarının düşük sıcaklıklarda haddelemesini içerir.',
                property: [
                    'DKP sac, düşük karbon içeriğine sahip olduğu için daha yumuşak ve şekillendirilebilir bir yapıya sahiptir.',
                    'Soğuk haddeleme işlemi, sac levhalarının daha ince bir şekilde işlenmesini sağlar. Bu da DKP sacı daha pürüzsüz yapar.',
                    'DKP sac genellikle paslanmayı önlemek için çinko, alüminyum veya boyalı kaplamalarla korunur.'
                ],
                pdfName:'dkp-sac'
            },
            {
                productID: 'product02',
                title: 'GALVANİZ SAC',
                content: 'Galvaniz sac, genellikle çelik sacın yüzeyine çinko kaplanarak koruyucu bir tabaka oluşturulmasıyla elde edilen bir tür sactır. Bu işlem genellikle "galvanizleme" olarak adlandırılır.',
                image: 'galvaniz-sac',
                imagePage: 'urunlerimiz',
                url: 'galvaniz-sac',
                subContent:'Galvaniz sac, genellikle çelik sacın yüzeyine çinko kaplanarak koruyucu bir tabaka oluşturulmasıyla elde edilen bir tür sactır. Bu işlem genellikle "galvanizleme" olarak adlandırılır. Çinko kaplama, çelik sacın paslanmasını önlemeye ve dayanıklılığını artırmaya yardımcı olur. Galvaniz sacın çelik sacın özelliklerine ek olarak çinkonun korozyona karşı dayanıklılığı da bulunur.',
                property: [
                    'Çinko kaplama, sacın yüzeyini korozyona karşı korur ve paslanmayı önler. Bu sayede, galvaniz sacı daha uzun ömürlü yapar.',
                    'Galvaniz sac, çinko kaplamasıyla parlak ve düzgün bir görünüme sahiptir. Bu estetik görünüm, tercih edilmesinde etkilidir.',
                    'Galvaniz sac, çelik sacın dayanıklılığına ek olarak çinkonun korozyona karşı dayanıklılığından da faydalanır.'
                ],
                pdfName:'galvaniz-sac'
            },
            {
                productID: 'product03',
                title: 'SİYAH SAC',
                content: 'Siyah sac, genellikle çelik üretiminde kullanılan ve doğal haliyle koruyucu bir kaplama olmadan üretilen bir tür çelik sacdır.',
                image: 'siyah-sac',
                imagePage: 'urunlerimiz',
                url: 'siyah-sac',
                subContent:'Siyah sac, genellikle çelik üretiminde kullanılan ve doğal haliyle koruyucu bir kaplama olmadan üretilen bir tür çelik sacdır. Siyah sac, çelik üretiminden sonra genellikle yağlama veya pasivasyon gibi işlemlerden geçirilir, ancak çinko kaplama gibi koruyucu kaplamalara sahip değildir.',
                property: [
                    'Siyah sac, çelik üretiminden sonra özel bir koruyucu kaplama olmadan üretilir. Bu nedenle, doğal rengi gri veya siyahtır.',
                    'Siyah sac, çelik üretiminde temel bir malzemedir. Farklı kalınlıklarda ve boyutlarda üretilebilir.',
                    'Şekillendirme, kesme ve kaynak gibi işlemlere kolayca tabi tutulabilir. Bu özellik, çeşitli uygulamalarda esneklik sağlar.'
                ],
                pdfName:'siyah-sac-olcum'

            },
            {
                productID: 'product04',
                title: 'BAKLAVA DESENLİ SAC',
                content: 'Baklava desenli sac, genellikle metal sacın yüzeyine işlenen, geometrik desenlerin oluşturduğu bir desen çeşididir. Bu desen, sac levhasının üzerine özel desene aittir.',
                image: 'baklava-sac',
                imagePage: 'urunlerimiz',
                url: 'baklava-sac',
                subContent:'Baklava desenli sac, genellikle metal sacın yüzeyine işlenen, geometrik desenlerin oluşturduğu bir desen çeşididir. Bu desen, sac levhasının üzerine özel kalıplarla preslenerek veya lazer kesim yöntemiyle uygulanır. Adını baklava tatlısının iç içe geçmiş şekillerinden alır.',
                property: [
                    'Baklava desenli sacın belirgin ve estetik görünümü, birçok uygulamada tercih edilmesini sağlar.',
                    'Metal sacın dayanıklılığı, baklava desenli sacın da dayanıklı olmasını sağlar. Darbelere ve dış etkilere karşı dayanıklıdır.',
                    'Galvaniz kaplamalı veya paslanmaz çelikten üretilen baklava desenli sac, korozyona karşı dirençlidir.'
                ],
                pdfName:'baklava-sac'
            },
            {
                productID: 'product05',
                title: 'TRAPEZ SAC',
                content: 'Trapez sac, trapez şeklinde profilli olan ve genellikle çatı kaplama ve duvar kaplama gibi yapısal uygulamalarda kullanılan bir tür metal sacdır.',
                image: 'trapez-sac',
                imagePage: 'urunlerimiz',
                url: 'trapez-sac',
                subContent:'Trapez sac, trapez şeklinde profilli olan ve genellikle çatı kaplama ve duvar kaplama gibi yapısal uygulamalarda kullanılan bir tür metal sacdır. Bu sac, düz bir levhanın kenarları yükseltilerek veya alt kısmı genişletilerek trapez şeklinde bir profil oluşturulmasıyla üretilir.',
                property: [
                    'Trapez sacın trapez şeklindeki profili, su ve kar birikmesini önlemek için çatı ve duvar kaplamalarında etkili bir drenaj sağlar.',
                    'Metal malzemenin hafifliği ve dayanıklılığı, trapez sacın kolay taşınabilir olmasını ve yapısal dayanıklılığını artırmasını sağlar.',
                    'Trapez sac, genellikle birbiriyle kolayca monte edilebilir ve sabitlenebilir. Bu, montaj sürecini hızlandırır.'
                ],
                pdfName:'trapez-sac'
            },
            {
                productID: 'product06',
                title: 'SICAK HADDELENMİŞ SAC',
                content: 'Sıcak haddelenmiş sac, yüksek sıcaklıklarda haddeleme işlemine tabi tutularak üretilen bir çelik ürünüdür. Bu işlem, sac levhalarının erimiş çelik halindedir.',
                image: 'sıcak-haddelenmis-sac',
                imagePage: 'urunlerimiz',
                url: 'sicak-haddelenmis-sac',
                subContent:'Sıcak haddelenmiş sac, yüksek sıcaklıklarda haddeleme işlemine tabi tutularak üretilen bir çelik ürünüdür. Bu işlem, sac levhalarının erimiş çelik halindeyken yüksek basınç altında kalıplara dökülmesi ve ardından soğutulmasıyla gerçekleştirilir. İnşaat sektöründe, sıcak haddelenmiş sac, yapısal elemanlar, kolonlar, kirişler ve taşıyıcı yapılar gibi birçok uygulamada kullanılır.',
                property: [
                    'Sıcak haddelenmiş sac, boru üretiminde kullanılan çeşitli çelik boru türlerinin üretiminde kullanılır.',
                    'Çeşitli makine ve ekipmanların yapısal bileşenleri, şase parçaları, krank mili gibi parçaların üretiminde kullanılır.',
                    'Endüstriyel depolama tankları, raf sistemleri ve konteynerler gibi depolama ekipmanlarının imalatında kullanılır.'
                ],
            },
            {
                productID: 'product07',
                title: 'SOĞUK HADDELENMİŞ SAC',
                content: 'Soğuk haddelenmiş sac, oda sıcaklığında haddeleme işlemine tabi tutularak üretilen bir çelik ürünüdür. Bu işlem, çelik levhaların soğuk haddelenerek',
                image: 'soguk-haddelenmis-sac',
                imagePage: 'urunlerimiz',
                url: 'soguk-haddelenmis-sac',
                subContent:'Soğuk haddelenmiş sac, oda sıcaklığında haddeleme işlemine tabi tutularak üretilen bir çelik ürünüdür. Bu işlem, çelik levhaların soğuk haddelenerek istenilen kalınlık ve boyutlara getirilmesini içerir. Soğuk haddelenmiş sac, konserve kutuları, metal kapaklar, teneke kutular ve ambalaj kaplamaları gibi ürünlerin üretiminde de kullanılır.',
                property: [
                    'Buzdolapları, çamaşır makineleri, bulaşık makineleri ve fırınlar gibi ev aletlerinin dış kaplamaları ve parçaları için kullanılır.',
                    'Elektrik panoları, kablo kanalları, elektrik kabinetleri ve elektronik bileşenlerin imalatında kullanılır.',
                    'Soğuk haddelenmiş sac, metal levha şekillendirme ve işleme işlemleri için yaygın olarak kullanılır.'
                ],
            },
            {
                productID: 'product08',
                title: 'HRP RULO/ PAKET SAC',
                content: 'HRP sac, "Hot Rolled Pickled" kısaltmasından gelir ve sıcak haddeleme işlemine tabi tutulmuş, ardından asitle yıkanmış ve paslanmaya karşı koruma yapar.',
                image: 'hrp-rulo-paket-sac',
                imagePage: 'urunlerimiz',
                url: 'hrp-rulo-paket-sac',
                subContent:'HRP sac, "Hot Rolled Pickled" kısaltmasından gelir ve sıcak haddeleme işlemine tabi tutulmuş, ardından asitle yıkanmış ve paslanmaya karşı koruma sağlamak için yağlanmış çelik sac levhalardır. Bu işlem, çelik yüzeyindeki oksit tabakasını temizler ve yüzeyin pürüzsüz olmasını sağlar.',
                property: [
                    'HRP sac, araç gövde parçaları, kapılar, şasi ve çeşitli bileşenlerin üretiminde yaygın olarak kullanılır.',
                    'Elektrik panoları, elektronik kabinler ve diğer elektrikli ve elektronik cihazların yapısal bileşenlerinde kullanılır.',
                    'Çeşitli makine ve ekipmanların yapısal bileşenleri, gövdeleri ve çeşitli parçaların üretiminde kullanılır.'
                ],
                pdfName:'hrp-sac'
            },
            {
                productID: 'product09',
                title: 'GALVANİZLİ RULO/PAKET SAC',
                content: 'Galvanizli rulo sac, çelik levhaların sıcak haddeleme işlemine tabi tutulduktan sonra, çinko kaplama işlemine geçirilerek üretilen bir çelik ürünüdür.',
                image: 'galvaniz-rulo-paket-sac',
                imagePage: 'urunlerimiz',
                url: 'galvaniz-rulo-paket-sac',
                subContent:'Galvanizli rulo sac, çelik levhaların sıcak haddeleme işlemine tabi tutulduktan sonra, çinko kaplama işlemine geçirilerek üretilen bir çelik ürünüdür. Bu işlem, çelik levhanın yüzeyine çinko tabakası uygulanmasıyla gerçekleşir, bu da çelik yüzeyini korozyona karşı korur.',
                property: [
                    'İnşaat sektöründe yapı elemanları, duvar kaplamaları, çatılar, kapı ve pencere profilleri gibi birçok uygulamada kullanılır.',
                    'Tarım sektöründe, galvanizli rulo sac, traktörler, tarım makineleri ve ekipmanlarının bileşenleri olarak kullanılır.',
                    'Ambalaj endüstrisinde kutular, teneke kutular ve diğer ambalaj malzemelerinin üretiminde kullanılır.'
                ],
            },
            {
                productID: 'product10',
                title: 'BOYALI RULO SAC',
                content: 'Boyalı rulo sac, çelik levhaların galvanizleme işleminden geçirilmesi ve ardından özel bir boya veya kaplama işlemi uygulanır.',
                image: 'boyali-rulo-sac',
                imagePage: 'urunlerimiz',
                url: 'boyali-rulo-sac',
                subContent:'Boyalı rulo sac, çelik levhaların galvanizleme işleminden geçirilmesi ve ardından özel bir boya veya kaplama işlemi uygulanarak elde edilen bir çelik ürünüdür. Bu işlem, çelik yüzeyini paslanmaya ve korozyona karşı korurken, aynı zamanda çeşitli renklerde ve desenlerde estetik bir görünüm sağlar.',
                property: [
                    'İnşaat sektöründe çatı kaplama malzemesi olarak kullanılır. Estetik ve dayanıklı bir çözüm sunar.',
                    'Estetik görünümü ve dayanıklı kaplaması, ev aletlerinin dış görünümünü iyileştirir ve uzun ömürlü kullanım sağlar.',
                    'Mobilya, paneller, kapılar, çitler, kutular ve diğer dekoratif ürünlerin üretiminde kullanılabilir.'
                ],
            },
            {
                productID: 'product11',
                title: 'ALÜMİNYUM SAC',
                content: 'Alüminyum sac, alüminyumun farklı kalınlıklarda haddeleme veya dökme işlemleriyle üretilen düz levhalardır. Genellikle hafif, dayanıklıdır.',
                image: 'alimunyum-sac',
                imagePage: 'urunlerimiz',
                url: 'aluminyum-sac',
                subContent:'Alüminyum sac, alüminyumun farklı kalınlıklarda haddeleme veya dökme işlemleriyle üretilen düz levhalardır. Genellikle hafif, dayanıklı ve korozyona karşı dirençlidirler. Alüminyum sac, hafif, dayanıklı ve korozyona karşı dirençli özellikleri nedeniyle birçok endüstriyel uygulamada tercih edilen bir malzemedir.',
                property: [
                    'İlaç ambalajı ve kozmetik ürün ambalajı gibi birçok ambalaj uygulamasında kullanılır. Dayanıklı, hafif ve hijyeniktir.',
                    'İnşaat sektöründe çatı kaplaması, dış cephe kaplaması, pencere ve kapı çerçeveleri gibi birçok uygulamada kullanılır.',
                    'Elektrik kablolarının koruyucu kaplaması, elektronik cihazların gövdeleri ve soğutucu panelleri gibi birçok yerde kullanılır.'
                ],
            },
            {
                productID: 'product12',
                title: 'DELİKLİ SAC',
                content: 'Delikli sac, çeşitli çelik veya metal levhaların özel makine işlemleriyle delikli desenlerle işlenerek elde edilen bir üründür.',
                image: 'delikli-sac',
                imagePage: 'urunlerimiz',
                url: 'delikli-sac',
                subContent:'Delikli sac, çeşitli çelik veya metal levhaların özel makine işlemleriyle delikli desenlerle işlenerek elde edilen bir üründür. Bu delikler, genellikle düzenli desenler veya özelleştirilmiş tasarımlar olabilir. Delikli sac, geniş bir kullanım yelpazesine sahip çok yönlü bir üründür ve dayanıklı, estetik ve işlevsel birçok uygulamada tercih edilir.',
                property: [
                    'Delikli sac hava akışını kontrol etmek, sıcaklık ve nem düzeylerini düzenlemek için kullanılır.',
                    'Delikli sac, aydınlatma armatürleri ve lambaların gövdesinde dekoratif aydınlatma panellerinde kullanılabilir.',
                    'Duvar panelleri, perde duvarlar, tavan panelleri, döşemeler ve mobilya gibi birçok uygulamada estetik dokunuş sağlar.'
                ],
                pdfName:'delikli-sac'
            },
            {
                productID: 'product13',
                title: 'GENLEŞTİRİLMİŞ SAC',
                content: 'Genleştirilmiş sac, çelik veya metal levhaların özel bir işlemden geçirilerek, düz levhaların üzerine delikler açılarak veya özel bir desen uygulanır.',
                image: 'genlestirilmis-sac',
                imagePage: 'urunlerimiz',
                url: 'genlestirilmis-sac',
                subContent:'Genleştirilmiş sac, çelik veya metal levhaların özel bir işlemden geçirilerek, düz levhaların üzerine delikler açılarak veya özel bir desen uygulanarak elde edilen bir üründür. Bu işlem, levhaların genişlemesini sağlar ve daha fazla dayanıklılık ve mukavemet sağlar.',
                property: [
                    'Zemin kaplamaları, merdiven basamakları, paslanmaz çelik korkuluklar ve gibi birçok inşaat uygulamasında kullanılır.',
                    'Güvenlik panelleri, çitler, kafesler ve koruyucu kaplamalar gibi güvenlik ve koruma amaçlı uygulamalarda kullanılır.',
                    'Kapı süslemeleri, mobilya tasarımları ve endüstriyel tasarım gibi birçok dekoratif uygulamada estetik  görünüm sağlar.'
                ],
                pdfName:'genisletilmis-sac'
            },
            
        ],
    },
    {
        category: 'profil-borular',
        title: 'Profil ve Borular',
        url:'profil-borular',
        products: [
            {
                productID: 'product05',
                title: 'KARE PROFİL',
                content: 'Kare profil, dört eşit kenara sahip bir dikdörtgen şeklindeki metal veya çelik profil çeşididir. Bu profil çeşidi genellikle kesitinin kare olmasından dolayı adını almıştır. Kare profillerin kullanım alanları oldukça geniştir ve çeşitli endüstriyel ve inşaat uygulamalarında tercih edilir.',
                image: 'kare-profil',
                imagePage: 'urunlerimiz',
                url: 'kare-profil',
                subContent:'Kare dolu demir, genel montaj veya üretim için birçok farklı endüstride yaygın olarak kullanılan hadde ürünleridir. Kare dolu demir her türlü malzemeden üretilebilen bir hadde ürünüdür. Mükemmel ağırlık taşıma özellikleri, yüksek korozyon direnci, üst seviye dayanıklılık bu ürünün diğer dikkat çeken özellikleridir.',
                property: [
                    'Kare profiller, çeşitli yapısal bileşenlerin ve taşıyıcı sistemlerin yapımında kullanılır.',
                    'Genellikle mukavemet gerektiren ve düzgün bir yapıya ihtiyaç duyulan uygulamalarda tercih edilirler.',
                    'Özellikle metal mobilya çerçeveleri, raf sistemleri, masa ayakları ve elemanları gibi ürünlerin yapımında kullanılırlar.'
                ],
                pdfName:'kare-profil'

            },
            {
                productID: 'product06',
                title: 'DİKDÖRTGEN PROFİL',
                content: 'Dikdörtgen profil, uzunlamasına iki uzun kenara ve iki kısa kenara sahip dikdörtgen şeklindeki metal veya çelik profil çeşididir.',
                image: 'dikdortgen-profil',
                imagePage: 'urunlerimiz',
                url: 'dikdortgen-profil',
                subContent:'Dikdörtgen profil, uzunlamasına iki uzun kenara ve iki kısa kenara sahip dikdörtgen şeklindeki metal veya çelik profil çeşididir. Bu profil çeşidi, genellikle kesitinin dikdörtgen olmasından dolayı adını almıştır. Dikdörtgen profiller, geniş kullanım alanlarına sahiptir ve çeşitli endüstriyel, inşaat ve dekoratif uygulamalarda tercih edilirler.',
                property: [
                    'Makine şasileri, taşıyıcı sistemler, makine çerçeveleri ve montaj kısımları gibi endüstriyel ekipmanların yapımında kullanılır.',
                    'Araç gövde parçaları, araç çerçeveleri, araba döşemeleri ve kargo alanları gibi birçok otomotiv uygulamasında kullanılır.',
                    'Merdiven korkulukları, çitler, pergolalar ve dekoratif kaplamalar gibi uygulamalarda tercih edilirler.'
                ],
                pdfName:'dikdortgen-profil'
            },
            {
                productID: 'product04',
                title: 'ANTİPAS BOYALI PROFİL',
                content: '“Ral Kodu 3009” Boyalı profil, genellikle çelikten imal edilen ve üzerine özel bir boya veya kaplama uygulanarak koruyucu bir katman oluşturulan bir çeşit profildir.',
                image: 'antipas-boyali-profil',
                imagePage: 'urunlerimiz',
                url: 'antipas-boyali-profil',
                subContent:'Boyalı profil, genellikle çelikten imal edilen ve üzerine özel bir boya veya kaplama uygulanarak koruyucu bir katman oluşturulan bir çeşit profildir. Bu kaplama, çelik yüzeyini oksidasyon, korozyon ve diğer dış etkenlerden korurken aynı zamanda estetik bir görünüm sağlar.',
                property: [
                    'Boyalı profiller, çelik yüzeyinin oksidasyonunu ve korozyonunu önlemek için uygulanan özel bir boya ile kaplanır.',
                    'Boyalı profiller, çeşitli renklerde mevcut olan boya kaplaması sayesinde estetik bir görünüme sahiptir.',
                    'Boyalı profiller, düzenli olarak bakım yapılarak uzun süre dayanabilir. Bu, yapıların bakım maliyetlerini azaltır.'
                ],
                pdfName:'boyali-profil'
            },
            {
                productID: 'product12',
                title: 'FERFORJE PROFİL',
                content: 'Ferforje profil, genellikle demir veya çelik malzemeden yapılan ve özel olarak tasarlanmış dekoratif profil çeşitidir .Genellikle çeşitli desenlerle işlenmiştir.',
                image: 'ferforje-profil',
                imagePage: 'urunlerimiz',
                url: 'ferforje-profil',
                subContent:'Ferforje profil, genellikle demir veya çelik malzemeden yapılan ve özel olarak tasarlanmış dekoratif profil çeşitleridir. Genellikle çeşitli desenlerle işlenmiş ve estetik bir görünüme sahiptirler. Ferforje profilleri, genellikle mimari ve dekoratif amaçlar için kullanılır ve farklı şekil ve boyutlarda bulunabilirler.',
                property: [
                    'Ferforje profilleri, kapı ve pencerelerin süslenmesi ve güçlendirilmesi amacıyla kullanılır.',
                    'Ferforje profilleri, merdiven korkuluklarının yapımında kullanılabilir ve bu alanda estetik bir dokunuş sağlarlar.',
                    'Lamba direkleri, duvar panoları ve dekoratif raflar gibi ürünlerde ferforje detaylar bulunabilir.'
                ],
            },
            {
                productID: 'product01',
                title: 'SANAYİ BORUSU',
                content: 'Sanayi boruları, genellikle endüstriyel uygulamalarda kullanılan çelikten imal edilen boru çeşitleridir. Bu borular, çeşitli sektörlerde farklı amaçlar için kullanılır',
                image: 'sanayi-borusu',
                imagePage: 'urunlerimiz',
                url: 'sanayi-borusu',
                subContent:'Sanayi boruları, genellikle endüstriyel uygulamalarda kullanılan çelikten imal edilen boru çeşitleridir. Bu borular, çeşitli sektörlerde farklı amaçlar için kullanılır ve genellikle büyük çaplı ve dayanıklı yapıya sahiptirler. Sanayi boruları, endüstriyel tesislerde, petrol ve gaz endüstrisinde, kimya fabrikalarında, su ve atık su sistemlerinde yaygın olarak kullanılmaktadır.',
                property: [
                    'Isıtma, soğutma, havalandırma ve atık su taşıma gibi işlevler için kullanılır.',
                    'Petrol rafinerileri, gaz boru hatları ve petrol kuyularında petrol ve gaz taşıma için kullanılır.',
                    'Su ve atık suyun taşınması ve dağıtımı için büyük tesislerde ve endüstriyel tesislerde kullanılır.'
                ],
            },
            {
                productID: 'product02',
                title: 'GALVANİZ BORU',
                content: 'Galvaniz boru, çelik boruların yüzeyine çinko kaplanması işlemiyle üretilen boru türüdür. Bu işlem, çelik boruların paslanmayı önlemek',
                image: 'galvaniz-boru',
                imagePage: 'urunlerimiz',
                url: 'galvaniz-boru',
                subContent:'Galvaniz boru, çelik boruların yüzeyine çinko kaplanması işlemiyle üretilen boru türüdür. Bu işlem, çelik boruların paslanmayı önlemek, korozyona karşı direnç sağlamak ve dayanıklılığını artırmak için uygulanır.Galvaniz borular, çeşitli endüstriyel, inşaat ve su tesisatı uygulamalarında yaygın olarak kullanılır.',
                property: [
                    'Özellikle dış mekanlarda ve nemli ortamlarda, galvaniz borular paslanmayı önler ve yapıların dayanıklılığını artırır.',
                    'Çinko kaplaması, boruların korozyona karşı dayanıklılığını artırarak uzun ömürlü bir tesisat sistemine katkı sağlar.',
                    'Endüstriyel tesislerde, çeşitli akışkanların taşınması, atık su tahliyesi ve havalandırma sistemlerinin yapımında kullanılır.'
                ],
            },
            {
                productID: 'product03',
                title: 'DOĞALGAZ BORUSU',
                content: 'Doğal gaz boruları, doğal gazın iletiminde ve dağıtımında kullanılan boru hatlarıdır. Genellikle yüksek dayanıklılığa sahip çeliktir.',
                image: 'dogalgaz-boru',
                imagePage: 'urunlerimiz',
                url: 'dogalgaz-boru',
                subContent:'Doğal gaz boruları, doğal gazın iletiminde ve dağıtımında kullanılan boru hatlarıdır. Genellikle yüksek dayanıklılığa sahip çelik veya polietilen gibi malzemelerden imal edilirler ve doğal gazın güvenli bir şekilde iletilmesini sağlarlar. Bu boruların kullanımı, doğal gazın evlerden endüstriyel tesislere kadar geniş bir alanda kullanılmasını mümkün kılar.',
                property: [
                    'Doğal gaz boruları, doğal gazın binalara ulaştırılmasında kullanılır. Evlerde, işyerlerinde ısıtma, sıcak su gibi ihtiyaçları giderir.',
                    'Elektrik üretimi için kullanılan doğal gaz santrallerinde, doğal gazın santralda kullanılan tesislere iletilmesinde kullanılır.',
                    'Endüstriyel tesislerde, doğal gaz bazlı proseslerde kullanılan ekipmanlar ve cihazlar için doğal gaz boruları kullanılır.'
                ],
            },
        ],
    },
    {
        category: 'panel-cesitleri',
        title: 'Panel Çeşitleri',
        url:'panel-cesitleri',
        products: [
            {
                productID: 'product02',
                title: 'SANDVİÇ PANEL',
                content: 'Sandviç panel, genellikle izolasyon malzemeleri arasına yerleştirilmiş iki dış yüzey plakasından oluşan bir yapı malzemesidir.',
                image: 'sandavic-panel',
                imagePage: 'urunlerimiz',
                url: 'sandvic-panel/kategoriler',
                subContent:'Sandviç panel, genellikle izolasyon malzemeleri arasına yerleştirilmiş iki dış yüzey plakasından oluşan bir yapı malzemesidir. Bu dış yüzey plakaları genellikle metal, çelik, alüminyum, fiberglass gibi dayanıklı malzemelerden yapılırken, arasındaki izolasyon malzemesi genellikle poliüretan, polistiren, cam elyafı veya mineral yün gibi yalıtım malzemelerinden oluşur.',
                property: [
                    'Sandviç paneller, yapıyı yalıtarak enerji verimliliğini artırır ve iç mekanın sıcaklığını ve ses düzeyini kontrol altında tutar.',
                    'Çiftlikler de sandviç panelleri kullanabilir. İç mekanın sıcaklığını, nem seviyesini kontrollü tutarak hayvanların sağlığını korur.',
                    'Konteyner  ve benzeri yapılar, sandviç panellerle kaplanarak hızlı bir şekilde inşa edilebilir ve iyi ölçüde izolasyon sağlar.'
                ],
                subcategory:[
                    {
                        productID: 'product05',
                        title: 'POLİÜRETAN/ PIR DOLGULU',
                        content: 'Poliüretan PIR (Polyisocyanurate) dolgulu panel, poliüretan köpüğün yüksek yoğunluklu versiyonu olan poliüretan pır dolgu malzemesi ile doldurulmuş bir yapı panelidir.',
                        image: 'poliuretan-panel',
                        imagePage: 'urunlerimiz',
                        url: 'poliuretan-panel',
                        subContent:'Poliüretan PIR (Polyisocyanurate) dolgulu panel, poliüretan köpüğün yüksek yoğunluklu versiyonu olan poliüretan pır dolgu malzemesi ile doldurulmuş bir yapı panelidir. Bu paneller, yüksek ısı yalıtımı sağlayan, hafif ve dayanıklı yapı malzemeleridir. Çeşitli endüstriyel ve ticari uygulamalarda kullanılan etkili bir yapı malzemesidir.',
                        property: [
                            'Prefabrik yapıların duvar, tavan ve çatı panellerinde polistren dolgulu paneller tercih edilir.',
                            'Gıda endüstrisindeki soğuk hava depoları ve dondurucuların yapımında kullanılırlar.',
                            'Gıda ve ilaç endüstrisindeki tesislerde depo ve taşıma alanlarının izolasyonu için ideal bir seçenektir.'
                        ],
                        subcategory:[
                            {
                                title:'3 HADVELİ BİNİLİ ÇATI PANELİ',
                                content:'3 Hadveli Binili Çatı Paneli," çatı kaplama sistemlerinde kullanılan bir malzeme türüdür. "3 Hadveli" ifadesi, panelin üç katmandan oluştuğunu belirtir.',
                                image:'3haddeli-panel',
                                imagePage:'urunlerimiz',
                                subContent:'3 Hadveli Binili Çatı Paneli," çatı kaplama sistemlerinde kullanılan bir malzeme türüdür. "3 Hadveli" ifadesi, panelin üç katmandan oluştuğunu belirtir.',
                                property:[
                                    'Faydalı En : 1000 mm',
                                    'Minimum boy : 3 metre',
                                    'Poliüretan Yoğunluk (EN 1602) : 40 (±2) kg/m3',
                                    'Poliüretan Kalınlığı : 30-40-50-60-80-100 mm',
                                    'Isı İletim Katsayısı ? (EN 13165) : 0,022-0,024 W/mK',
                                    'Boyutsal Kararlılık (EN 13165) : Seviye DS (TH) 11',
                                    'Yanmazlık Sınıfı (EN 13501) : B. S2 . d0',
                                    'Su absorbsiyonu (EN ISO 354) : Hacimce %2 (168 saat)',
                                    'Kapalı hücre oranı (EN 14509) : 0,95',
                                    'Buhar Difüzyon Direnci (EN 12086) : 30-100',
                                    'Sıcaklık Dayanımı :-200 /+110 ºC'
                                ]
                            },
                            {
                                title:'5 HADVELİ BİNİLİ ÇATI PANELİ',
                                content:'5 Hadveli Binili Çatı Paneli," çatı kaplama sistemlerinde kullanılan bir yapı malzemesi türüdür. "5 Hadveli" ifadesi, panelin beş katmandan oluştuğunu belirtir.',
                                image:'5haddeli-panel',
                                imagePage:'urunlerimiz',
                                subContent:'5 Hadveli Binili Çatı Paneli," çatı kaplama sistemlerinde kullanılan bir yapı malzemesi türüdür. "5 Hadveli" ifadesi, panelin beş katmandan oluştuğunu belirtir.',
                                property:[
                                    'Faydalı En : 1000 mm',
                                    'Minimum boy : 3 metre',
                                    'Maksimum boy : Nakliye Koşullarına Bağlıdır',
                                    'Poliüretan Yoğunluk (EN 1602) : 40 (±2) kg/m3',
                                    'Poliüretan Kalınlığı : 40-50-60-80-100 mm',
                                    'Isı İletim Katsayısı ? (EN 13165) : 0,022-0,024 W/mK',
                                    'Boyutsal Kararlılık (EN 13165) : Seviye DS(TH) 11',
                                    'Yanmazlık Sınıfı (EN 13501) :B. S2 . d0',
                                    'Su absorbsiyonu (EN ISO 354) : Hacimce %2 (168 saat)',
                                    'Kapalı hücre oranı (EN 14509) :0,95',
                                    'Buhar Difüzyon Direnci (EN 12086) : 30-100',
                                    'Sıcaklık Dayanımı : -200 /+110 ºC'
                                ]
                            },
                            {
                                title:'MEBRANLI ÇATI PANELİ',
                                content:'Çelikten yapılmış çatı iskeleti üzerine monte edilir. "Membranlı" terimi, panelin yüzeyinde membran veya zar bulunduğunu ifade eder.',
                                image:'mebranli-panel',
                                imagePage:'urunlerimiz',
                                subContent:'Çelikten yapılmış çatı iskeleti üzerine monte edilir. "Membranlı" terimi, panelin yüzeyinde membran veya zar bulunduğunu ifade eder.',
                                property:[
                                    'Faydalı En : 1000 mm',
                                    'Minimum boy : 3 metre',
                                    'Maksimum boy : Nakliye Koşullarına Bağlıdır',
                                    'Poliüretan Yoğunluk (EN 1602) : 40 (±2) kg/m3',
                                    'Poliüretan Kalınlığı : 40-50-60-80-100 mm',
                                    'Isı İletim Katsayısı ? (EN 13165) : 0,022-0,024 W/mK',
                                    'Boyutsal Kararlılık (EN 13165) : Seviye DS(TH) 11',
                                    'Yanmazlık Sınıfı (EN 13501) :B. S2 . d0',
                                    'Su absorbsiyonu (EN ISO 354) : Hacimce %2 (168 saat)',
                                    'Kapalı hücre oranı (EN 14509) :0,95',
                                    'Buhar Difüzyon Direnci (EN 12086) : 30-100',
                                    'Sıcaklık Dayanımı : -200 /+110 ºC'
                                ]
                            },
                            {
                                title:'3 HADVELİ KEPLİ ÇATI PANELİ',
                                content:'Genellikle bir çekirdek malzeme ile çevresindeki iki farklı malzeme tabakasından oluşur. Bu tasarım, panelin dayanıklılığını artırır ve izolasyon sağlar.',
                                image:'3haddeli-kepli-panel',
                                imagePage:'urunlerimiz',
                                subContent:'Genellikle bir çekirdek malzeme ile çevresindeki iki farklı malzeme tabakasından oluşur. Bu tasarım, panelin dayanıklılığını artırır ve izolasyon sağlar.',
                                property:[
                                    'Faydalı En : 1000 mm',
                                    'Minimum boy : 3 metre',
                                    'Maksimum boy : Nakliye Koşullarına Bağlıdır',
                                    'Poliüretan Yoğunluk (EN 1602) : 40 (±2) kg/m3',
                                    'Poliüretan Kalınlığı : 40-50-60-80-100 mm',
                                    'Isı İletim Katsayısı ? (EN 13165) : 0,022-0,024 W/mK',
                                    'Boyutsal Kararlılık (EN 13165) : Seviye DS(TH) 11',
                                    'Yanmazlık Sınıfı (EN 13501) :B. S2 . d0',
                                    'Su absorbsiyonu (EN ISO 354) : Hacimce %2 (168 saat)',
                                    'Kapalı hücre oranı (EN 14509) :0,95',
                                    'Buhar Difüzyon Direnci (EN 12086) : 30-100',
                                    'Sıcaklık Dayanımı : -200 /+110 ºC'
                                ]
                            },
                            {
                                title:'W40 CEPHE PANELİ',
                                content:'W40 Cephe Paneli," dış cephe kaplama sistemlerinde kullanılan bir malzeme türüdür. Genellikle binaların dış yüzeylerinde estetik ve koruyucu bir kaplama olarak kullanılır.',
                                image:'w40-panel',
                                imagePage:'urunlerimiz',
                                subContent:'W40 Cephe Paneli," dış cephe kaplama sistemlerinde kullanılan bir malzeme türüdür. Genellikle binaların dış yüzeylerinde estetik ve koruyucu bir kaplama olarak kullanılır.',
                                property:[
                                    'Faydalı En : 1000 mm',
                                    'Minimum boy : 3 metre',
                                    'Maksimum boy : Nakliye Koşullarına Bağlıdır',
                                    'Poliüretan Yoğunluk (EN 1602) : 40 (±2) kg/m3',
                                    'Poliüretan Kalınlığı : 40mm',
                                    'Isı İletim Katsayısı ? (EN 13165) : 0,022-0,024 W/mK',
                                    'Boyutsal Kararlılık (EN 13165) : Seviye DS(TH) 11',
                                    'Yanmazlık Sınıfı (EN 13501) :B. S2 . d0',
                                    'Su absorbsiyonu (EN ISO 354) : Hacimce %2 (168 saat)',
                                    'Kapalı hücre oranı (EN 14509) :0,95',
                                    'Buhar Difüzyon Direnci (EN 12086) : 30-100',
                                    'Sıcaklık Dayanımı : -200 /+110 ºC'
                                ]
                            },
                            {
                                title:'W CEPHE PANELİ',
                                content:'W cephe panelleri, çeşitli malzemelerden üretilebilir. Metal, alüminyum kompozit, beton, cam elyafı ve sentetik malzemeler gibi çeşitli seçenekler mevcuttur.',
                                image:'W-panel',
                                imagePage:'urunlerimiz',
                                subContent:'W cephe panelleri, çeşitli malzemelerden üretilebilir. Metal, alüminyum kompozit, beton, cam elyafı ve sentetik malzemeler gibi çeşitli seçenekler mevcuttur.',
                                property:[
                                    'Faydalı En : 1000 mm',
                                    'Minimum boy : 3 metre',
                                    'Maksimum boy : Nakliye Koşullarına Bağlıdır',
                                    'Poliüretan Yoğunluk (EN 1602) : 40 (±2) kg/m3',
                                    'Poliüretan Kalınlığı : 50-60-70-80-100 mm',
                                    'Isı İletim Katsayısı ? (EN 13165) : 0,022-0,024 W/mK',
                                    'Boyutsal Kararlılık (EN 13165) : Seviye DS(TH) 11',
                                    'Yanmazlık Sınıfı (EN 13501) :B. S2 . d0',
                                    'Su absorbsiyonu (EN ISO 354) : Hacimce %2 (168 saat)',
                                    'Kapalı hücre oranı (EN 14509) :0,95',
                                    'Buhar Difüzyon Direnci (EN 12086) : 30-100',
                                    'Sıcaklık Dayanımı : -200 /+110 ºC'
                                ]
                            },
                            {
                                title:'SİNÜS CEPHE PANELİ',
                                content:'Sinüs cephe paneli, dış cephe kaplama sistemlerinde kullanılan bir tür paneldir. "Sinüs" terimi, panelin dış yüzeyinde dalgalı veya kıvrımlı bir görünüme sahip olması anlamına gelir.',
                                image:'sinus-panel',
                                imagePage:'urunlerimiz',
                                subContent:'Sinüs cephe paneli, dış cephe kaplama sistemlerinde kullanılan bir tür paneldir. "Sinüs" terimi, panelin dış yüzeyinde dalgalı veya kıvrımlı bir görünüme sahip olması anlamına gelir.',
                                property:[
                                    'Faydalı En : 1000 mm',
                                    'Minimum boy : 3 metre',
                                    'Maksimum boy : Nakliye Koşullarına Bağlıdır',
                                    'Poliüretan Yoğunluk (EN 1602) : 40 (±2) kg/m3',
                                    'Poliüretan Kalınlığı : 50-60-80 mm',
                                    'Isı İletim Katsayısı ? (EN 13165) : 0,022-0,024 W/mK',
                                    'Boyutsal Kararlılık (EN 13165) : Seviye DS(TH) 11',
                                    'Yanmazlık Sınıfı (EN 13501) :B. S2 . d0',
                                    'Su absorbsiyonu (EN ISO 354) : Hacimce %2 (168 saat)',
                                    'Kapalı hücre oranı (EN 14509) :0,95',
                                    'Buhar Difüzyon Direnci (EN 12086) : 30-100',
                                    'Sıcaklık Dayanımı : -200 /+110 ºC'
                                ]
                            },
                        ]
                    },
                    {
                        productID: 'product05',
                        title: 'TAŞYÜNÜ DOLGULU',
                        content: 'Taşyünü dolgulu panel, içerisinde taşyünü yalıtım malzemesi bulunan bir yapı panelidir. Bu panel, genellikle çeşitli binaların dış cephe kaplamalarında',
                        image: 'tasyon-panel',
                        imagePage: 'urunlerimiz',
                        url: 'tasyon-panel',
                        subContent:'Taşyünü dolgulu panel, içerisinde taşyünü yalıtım malzemesi bulunan bir yapı panelidir. Bu panel, genellikle çeşitli binaların dış cephe kaplamalarında ve çatılarında yalıtım amacıyla kullanılır. Dayanıklı, yangına dayanıklı ve yalıtım özellikleriyle dikkat çekerler. Bu özellikleri sayesinde çeşitli yapı tiplerinde ve endüstriyel tesislerde yaygın olarak kullanılırlar.',
                        property: [
                            'Taşyünü dolgulu paneller, binaların dış cephelerinin yalıtımında ve kaplamasında kullanılır.',
                            'Bu paneller, çatı üzerine monte edilerek iç mekanı dış hava koşullarından korur ve enerji tasarrufu sağlar.',
                            'İç mekanlarda duvarların yalıtımında da kullanılabilir. Ses yalıtımı ve termal yalıtım gerektiren alanlarda tercih edilirler.'
                        ],
                        subcategory:[
                            {
                                title:'ÇATI PANELİ',
                                content:'Çatı paneli, çatı kaplama sistemlerinde kullanılan bir tür yapı malzemesidir. Genellikle metal, alüminyum kompozit, çelik veya polikarbonat gibi malzemelerden üretilir.',
                                image:'alt-cati-paneli',
                                imagePage:'urunlerimiz',
                                subContent:'Çatı paneli, çatı kaplama sistemlerinde kullanılan bir tür yapı malzemesidir. Genellikle metal, alüminyum kompozit, çelik veya polikarbonat gibi malzemelerden üretilir.',
                                property:[
                                    'Faydalı Genişlik (mm) : 1000',
                                    'Hadve Yüksekliği (mm) : 35',
                                    'Hadve Sayısı (adet) : 4',
                                    'Metal Kalınlığı : 0.40 - 0.50 - 0.60 - 0.70',
                                    'Boya Tipi : Polyester - Pvdf - Plastisol',
                                    'Renk : 3009 - 9002',
                                    'Yalıtım Malzemesi : Taş Yünü',
                                    'Yalıtım Densitesi : min 90 - max 110',
                                    'Yalıtım Kalınlığı (mm) - h : 50 - 60 - 70 - 80 - 90 - 100',
                                    'Maximum Isı İletkenlik Değeri : ≤ 0.036',
                                    'Alın Kapama Modeli : (Pv5 - Pv6 - Pv7 - Pv8 - Pv9 - Pv10)',
                                    'Yangın Sınıfı : A2.S1.d0'
                                ]
                            },
                            {
                                title:'CEPHE PANELİ',
                                content:'Cephe paneli, binaların dış cephelerinde kullanılan bir tür yapı malzemesidir. Genellikle yapıların estetik görünümünü ve dış korumasını sağlamak amacıyla kullanılır.',
                                image:'cephe-paneli',
                                imagePage:'urunlerimiz',
                                subContent:'Cephe paneli, binaların dış cephelerinde kullanılan bir tür yapı malzemesidir. Genellikle yapıların estetik görünümünü ve dış korumasını sağlamak amacıyla kullanılır.',
                                property:[
                                    'Faydalı Genişlik (mm) : 1000',
                                    'Metal Kalınlığı : 0.50 - 0.60 - 0.70',
                                    'Boya Tipi :Polyester - Pvdf - Plastisol',
                                    'Renk : 9002',
                                    'Yalıtım Malzemesi : Taş Yünü',
                                    'Yalıtım Densitesi : min 90 - max 110',
                                    'Yalıtım Kalınlığı (mm) - h : 50 - 60 - 70 - 80 - 90 - 100',
                                    'Maximum Isı İletkenlik Değeri :≤ 0.035',
                                    'Alın Kapama Modeli : (Pv5 - Pv6 - Pv7 - Pv8 - Pv9 - Pv10)',
                                    'Yangın Sınıfı : A2.S1.d0',
                                    'Geçme Detayı : Gizli Vida - Üstten Vida'
                                ]
                            }
                        ]
                    },
                    {
                        productID: 'product05',
                        title: 'POLİSTREN DOLGULU',
                        content: 'Polistren dolgulu panel, polistiren köpük malzemesi ile doldurulmuş bir yapı panelidir. Bu paneller, hafif, yalıtımlı ve kolay monte edilirdir.',
                        image: 'polistran-panel',
                        imagePage: 'urunlerimiz',
                        url: 'polistr-panel',
                        subContent:'Polistren dolgulu panel, polistiren köpük malzemesi ile doldurulmuş bir yapı panelidir. Bu paneller, hafif, yalıtımlı ve kolay monte edilebilir özellikleriyle dikkat çekerler. Yüksek izolasyon sağlamaları ve kolay monte edilebilir olmaları nedeniyle çeşitli inşaat ve endüstriyel uygulamalarda yaygın olarak kullanılan bir yapı malzemesidir.',
                        property: [
                            'Prefabrik yapıların duvar, tavan ve çatı panellerinde polistren dolgulu paneller tercih edilir.',
                            'Gıda endüstrisindeki soğuk hava depoları ve dondurucuların yapımında kullanılırlar.',
                            'Gıda ve ilaç endüstrisindeki tesislerde depo ve taşıma alanlarının izolasyonu için ideal bir seçenektir.'
                        ],
                        subcategory:[
                            {
                                title:'BOYALI SAC ÇATI PANELİ',
                                content:'Boyalı sac çatı paneli, çatı kaplama sistemlerinde kullanılan bir tür malzemedir. Genellikle metal sac paneller üzerine boya kaplanarak üretilirler.',
                                image:'boyali-panel',
                                imagePage:'urunlerimiz',
                                subContent:'Boyalı sac çatı paneli, çatı kaplama sistemlerinde kullanılan bir tür malzemedir. Genellikle metal sac paneller üzerine boya kaplanarak üretilirler.',
                                property:[
                                    'Faydalı Genişlik (mm) : 1000',
                                    'Alt Metal Cinsi : Boyalı Galvaniz Sac',
                                    'Alt Metal Kalınlığı (mm) : 0.30 - 0.40 - 0.50 - 0.60 - 0.70',
                                    'Üst Metal Cinsi : Boyalı Galvaniz Sac',
                                    'Üst Metal Kalınlığı (mm) : 0.30 - 0.40 - 0.50 - 0.60 - 0.70',
                                    'Renk : 3009 - 9002',
                                    'Boya Tipi : Polyester, Pvdf, Plastisol',
                                    'Yalıtım Malzemesi : Polistren',
                                    'Yalıtım Dansitesi(kg/m3) : 15 - 30',
                                    'Yalıtım Kalınlığı (mm) - h : 30 - 40 - 50 - 60 - 70 - 80 - 90 - 100',
                                    'Maximum Isı İletkenlik Değeri :≤ eps 0,038 - Gümüş eps 0,032',
                                    'Alın Kapama Modeli : pp3-pp4-pp5-pp6-pp7-pp8-pp9-pp10',
                                    'Dışyangın Performansı : Broof (t1)',
                                    'Hadve sayısı (HRP) : 4'
                                ]
                            },
                            {
                                title:'ALÜMİNYUM ÇATI PANELİ',
                                content:'Alüminyum çatı paneli, çatı kaplama sistemlerinde kullanılan bir tür yapı malzemesidir. Genellikle alüminyum alaşımlarından üretilir ve hafif, dayanıklıdır.',
                                image:'alimunyum-panel',
                                imagePage:'urunlerimiz',
                                subContent:'Alüminyum çatı paneli, çatı kaplama sistemlerinde kullanılan bir tür yapı malzemesidir. Genellikle alüminyum alaşımlarından üretilir ve hafif, dayanıklıdır.',
                                property:[
                                    'Faydalı Genişlik (mm) : 1000',
                                    'Alt Metal Cinsi : Aluminyum',
                                    'Alt Metal Kalınlığı (mm) : 0.30 - 0.40 - 0.50 - 0.60 - 0.70',
                                    'Üst Metal Cinsi : Aluminyum',
                                    'Üst Metal Kalınlığı (mm) : 0.30 - 0.40 - 0.50 - 0.60 - 0.70',
                                    'Renk : Natürel - 5010',
                                    'Boya Tipi : Polyester, Pvdf, Plastisol',
                                    'Yalıtım Malzemesi : Polistren',
                                    'Yalıtım Dansitesi(kg/m3) : 15 - 30',
                                    'Yalıtım Kalınlığı (mm) - h : 30 - 40 - 50 - 60 - 70 - 80 - 90 - 100',
                                    'Maximum Isı İletkenlik Değeri :≤ eps 0,038 - Gümüş eps 0,032',
                                    'Alın Kapama Modeli : pp3-pp4-pp5-pp6-pp7-pp8-pp9-pp10',
                                    'Dışyangın Performansı : Broof (t1)',
                                    'Hadve sayısı (HRP) : 4'
                                ]
                            },
                            {
                                title:'CEPHE PANELİ',
                                content:'Cephe paneli, binaların dış cephelerinde kullanılan bir yapı malzemesidir. Genellikle yapıların estetik görünümünü ve dış korumasını sağlamak için kullanılır.',
                                image:'cephe-paneli',
                                imagePage:'urunlerimiz',
                                subContent:'Cephe paneli, binaların dış cephelerinde kullanılan bir yapı malzemesidir. Genellikle yapıların estetik görünümünü ve dış korumasını sağlamak için kullanılır.',
                                property:[
                                    'Faydalı Genişlik (mm) : 1000',
                                    'Alt Metal Cinsi : Boyalı Galvaniz Sac',
                                    'Alt Metal Kalınlığı (mm) : 0.30 - 0.40 - 0.50 - 0.60 - 0.70',
                                    'Üst Metal Cinsi : Boyalı Galvaniz Sac',
                                    'Üst Metal Kalınlığı (mm) : 0.30 - 0.40 - 0.50 - 0.60 - 0.70',
                                    'Renk : 3009 - 9002',
                                    'Boya Tipi : Polyester, Pvdf, Plastisol',
                                    'Yalıtım Malzemesi : Polistren',
                                    'Yalıtım Dansitesi(kg/m3) : 15 - 30',
                                    'Yalıtım Kalınlığı (mm) - h : 30 - 40 - 50 - 60 - 70 - 80 - 90 - 100',
                                    'Maximum Isı İletkenlik Değeri :≤ eps 0,038 - Gümüş eps 0,032',
                                    'Alın Kapama Modeli : pp3-pp4-pp5-pp6-pp7-pp8-pp9-pp10',
                                    'Dışyangın Performansı : Broof (t1)',
                                    'Geçme Detayı :Gizli Vida - Üstten Vida'
                                ]
                            }
                        ]
                    },
                ]
            },
            {
                productID: 'product03',
                title: 'PANEL ÇİT',
                content: 'Panel çit, genellikle metal malzemeden imal edilen ve çeşitli amaçlarla kullanılan bir tür çit sistemidir. Düzgün bir şekilde kaynaklanmıştır.',
                image: 'panel-cit',
                imagePage: 'urunlerimiz',
                url: 'panel-cit',
                subContent:'Panel çit, genellikle metal malzemeden imal edilen ve çeşitli amaçlarla kullanılan bir tür çit sistemidir. Düzgün bir şekilde kaynaklanmış ve genellikle panelli yapısı olan bu çit türü, bahçe, arazi, endüstriyel alanlar, kamu binaları, otoparklar, okullar, spor tesisleri ve daha birçok alanda kullanılabilir.',
                property: [
                    'Panel çitler, mülk sınırlarının belirlenmesi ve güvenliğin sağlanması için yaygın olarak kullanılır.',
                    'Bahçe çitleri olarak da bilinen panel çitler, evlerin etrafında kullanılarak bahçelerin çevresini belirlemek için de kullanılır.',
                    'Kamu alanları, parklar, oyun alanları ve okul bahçelerinde çocukların güvenliğini sağlamak amacıyla panel çitler kullanılır.'
                ],
            },
            {
                productID: 'product05',
                title: 'POLİKARBON AYDINLATMA',
                content: 'Polikarbon aydınlatma levhası , genellikle polikarbonat malzemesinden üretilen ve çeşitli aydınlatma uygulamalarında kullanılan bir tür yapı panelidir. ',
                image: 'duz-binili-levha',
                imagePage: 'urunlerimiz',
                url: 'polikarbon-panel/kategoriler',
                subContent:'Polikarbon aydınlatma levhası paneli, genellikle polikarbonat malzemesinden üretilen ve çeşitli aydınlatma uygulamalarında kullanılan bir tür yapı panelidir. Bu paneller, yüksek dayanıklılığı, hafif yapısı ve ışık geçirgenliği özellikleriyle dikkat çekerler. Geniş kullanım alanları ve sağladıkları estetik ve fonksiyonel avantajlarla aydınlatma sektöründe popüler bir tercih haline gelmiştir.',
                property: [
                    'Sac Semerler Tamamlayıcı Üründür.',
                    'Trapez Vidalar Tamamlayıcı Üründür.',
                    'Poliüretan Mastik Tamamlayıcı Üründür.'
                ],
                subcategory:[
                    {
                        productID: 'product05',
                        title: 'DÜZ BİNİLİ POLİKARBON LEVHA',
                        content: 'Sandviç panel kullanılan çatılarda aydınlatma ihtiyacını gidermek için çokça kullanılır.',
                        image: 'duz-binili-levha',
                        imagePage: 'urunlerimiz',
                        url: 'poliuretan-panel',
                        subContent:'Sandviç panel kullanılan çatılarda aydınlatma ihtiyacını gidermek için çokça kullanılır.',
                        property: [
                            'Prefabrik yapıların duvar, tavan ve çatı panellerinde polistren dolgulu paneller tercih edilir.',
                            'Gıda endüstrisindeki soğuk hava depoları ve dondurucuların yapımında kullanılırlar.',
                            'Gıda ve ilaç endüstrisindeki tesislerde depo ve taşıma alanlarının izolasyonu için ideal bir seçenektir.'
                        ],
                        subcategory:[
                            {
                                title:'DÜZ BİNİLİ POLİKARBON LEVHA',
                                content:'3 Hadveli Binili Çatı Paneli," çatı kaplama sistemlerinde kullanılan bir malzeme türüdür. "3 Hadveli" ifadesi, panelin üç katmandan oluştuğunu belirtir.',
                                image:'duz-binili-levha',
                                imagePage:'urunlerimiz',
                                subContent:'3 Hadveli Binili Çatı Paneli," çatı kaplama sistemlerinde kullanılan bir malzeme türüdür. "3 Hadveli" ifadesi, panelin üç katmandan oluştuğunu belirtir.',
                                property:[
                                    'Faydalı En : 1000 mm',
                                    'Yalıtım kalınlığı seçenekleri (mm) : 30-40-50 mm',
                                    'Max Boy : 15 mt',
                                    'U değeri (W/m2K) : 30 mm kalınlık = 1,44',
                                    'Işık Geçirgenlik : 30 mm kalınlık = %64',
                                ]
                            },
                        ]
                    },
                    {
                        productID: 'product05',
                        title: 'TRAPEZ FORMUNDA POLİKARBON LEVHA',
                        content: 'Sandviç panel kullanılan çatılarda aydınlatma ihtiyacını gidermek için kullanılır. Aydınlatma amaçlı tüm sanayi tipi,alışveriş merkezi,çiftlik,konut vb alanlarda kullanma olanağı mevcuttur.',
                        image: 'trapez-polikarbon',
                        imagePage: 'urunlerimiz',
                        url: 'polistr-panel',
                        subContent:'Sandviç panel kullanılan çatılarda aydınlatma ihtiyacını gidermek için kullanılır. Aydınlatma amaçlı tüm sanayi tipi,alışveriş merkezi,çiftlik,konut vb alanlarda kullanma olanağı mevcuttur.',
                        property: [
                            'Prefabrik yapıların duvar, tavan ve çatı panellerinde polistren dolgulu paneller tercih edilir.',
                            'Gıda endüstrisindeki soğuk hava depoları ve dondurucuların yapımında kullanılırlar.',
                            'Gıda ve ilaç endüstrisindeki tesislerde depo ve taşıma alanlarının izolasyonu için ideal bir seçenektir.'
                        ],
                        subcategory:[
                            {
                                title:'TRAPEZ FORMUNDA POLİKARBON LEVHA',
                                content:'Sandviç panel kullanılan çatılarda aydınlatma ihtiyacını gidermek için kullanılır. Aydınlatma amaçlı tüm sanayi tipi,alışveriş merkezi,çiftlik,konut vb alanlarda kullanma olanağı mevcuttur.',
                                image:'trapez-polikarbon',
                                imagePage:'urunlerimiz',
                                subContent:'Sandviç panel kullanılan çatılarda aydınlatma ihtiyacını gidermek için kullanılır. Aydınlatma amaçlı tüm sanayi tipi,alışveriş merkezi,çiftlik,konut vb alanlarda kullanma olanağı mevcuttur.',
                                property:[
                                    'Faydalı En : 1000 mm',
                                    'Yalıtım kalınlığı seçenekleri (mm) : 10-12-20 mm',
                                    'Max Boy : 15 mt',
                                    'U değeri (W/m2K) : 12 mm kalınlık = 2,51',
                                    'Işık Geçirgenlik : 12 mm kalınlık = %77',
                                ]
                            },
                        ]
                    },
                ]
            },
            {
                productID: 'product03',
                title: 'TAMAMLAYICI ÜRÜNLER',
                content: 'Farklı Renk ve Ebatlarda Sac Semerler, Poliüretan Mastik, İnce Profiller İçin Panel ve Trapez Vidaları,Kalın Profiller İçin Panel ve Trapez Vidalarıdır.',
                image: 'tamamlayici-urunler',
                imagePage: 'urunlerimiz',
                url: 'tamamlayici-urunler/alturunleri',
                subContent:'Panel çit, genellikle metal malzemeden imal edilen ve çeşitli amaçlarla kullanılan bir tür çit sistemidir. Düzgün bir şekilde kaynaklanmış ve genellikle panelli yapısı olan bu çit türü, bahçe, arazi, endüstriyel alanlar, kamu binaları, otoparklar, okullar, spor tesisleri ve daha birçok alanda kullanılabilir.',
                property: [
                    'Sac Semerler Tamamlayıcı Üründür.',
                    'Trapez Vidalar Tamamlayıcı Üründür.',
                    'Poliüretan Mastik Tamamlayıcı Üründür.'
                ],
                subcategory:[
                    { title:'Sac Semerler', image:'sac-semerler', imagePage:'urunlerimiz', url:'' }, { title:'Trapez Vidaları', image:'trapez-vida01', imagePage:'urunlerimiz', url:'' }, { title:'Trapez Vidaları', image:'trapez-vida02', imagePage:'urunlerimiz', url:'' },
                ]
            },
            {
                productID: 'product03',
                title: 'SANDVİÇ PANEL ÜRÜN ALANLARI',
                content: 'Panel çeşitlerinin kullanım alanlarının gösterildiği örnek uygulama noktalarıdır. Snadvic panel ürün alanları birçok noktada görev alarak kullanılır.',
                image: 'sandvic-alan-panel',
                imagePage: 'urunlerimiz',
                url:'sandvic-alan-panel',
                subContent:'Panel çeşitlerinin kullanım alanlarının gösterildiği örnek uygulama noktalarıdır. Snadvic panel ürün alanları birçok noktada görev alarak kullanılır.',
                property: [
                    '',
                ],
            },
        ],
    },
    {
        category: 'profiller',
        title: 'Panel Çeşitleri',
        url:'profiller',
        products: [
            {
                productID: 'product01',
                title: 'NPI PROFİL',
                content: 'NPI profil, çelik yapı ve inşaat sektöründe kullanılan bir çelik profil çeşididir. NPI profil, "Normal Profil İ" olarak da bilinir. NPI profiller, çeşitli yapısal uygulamalarda kullanılır.',
                image: 'npi-profil',
                imagePage: 'urunlerimiz',
                url: 'npi-profil',
                subContent:'NPI profil, çelik yapı ve inşaat sektöründe kullanılan bir çelik profil çeşididir. NPI profil, "Normal Profil İ" olarak da bilinir. NPI profiller, çeşitli yapısal uygulamalarda kullanılmak üzere tasarlanmıştır ve I şeklindeki kesite sahiptir. Bu profiller, genellikle kirişler, kolonlar, taşıyıcı elemanlar ve benzeri yapısal bileşenlerin imalatında kullanılır.',
                property: [
                    'Endüstriyel makinelerin taşıyıcı yapılarında ve iş makinelerinin şasilerinde kullanılır. Makinelerin gövdeleri için idealdirler.',
                    'Gemi yapımında ve denizcilik endüstrisinde kullanılır. Bu profiller, gemi yapılarının güçlendirilmesi için kullanılabilir.',
                    'NPI profiller, raf sistemleri, vinç kirişleri ve taşıma konveyörlerinde kullanılabilirler.'
                ],
                pdfName:'npi-olcum'
            },
            {
                productID: 'product02',
                title: 'NPU PROFİL',
                content: 'NPU profil, özellikle çelik yapı ve inşaat sektöründe kullanılan, U şeklinde bir profildir. NPU profil, "Normal Profil U" olarak da bilinir.',
                image: 'npu-profil',
                imagePage: 'urunlerimiz',
                url: 'npu-profil',
                subContent:'NPU profil, özellikle çelik yapı ve inşaat sektöründe kullanılan, U şeklinde bir profildir. NPU profil, "Normal Profil U" olarak da bilinir. NPU profiller, genellikle çelik yapıların çeşitli parçalarının imalatında kullanılır ve çeşitli boyutlarda ve kalınlıklarda üretilebilir. Bu profiller, genellikle çelik konstrüksiyon, çatı ve duvar kaplamaları benzeri uygulamalarda kullanılır.',
                property: [
                    'NPU demirler, yüksek taşıma kapasitesi sayesinde büyük yapıların taşıyıcı sistemlerinde önemli bir rol oynar.',
                    'Farklı boyut ve şekillerde üretilebilen NPU demirler, tasarım açısından büyük esneklik sunar.',
                    'Geniş bir yelpazede kullanılarak yapıların estetik açıdan çekici bir görünüm kazanmasını sağlarlar.'
                ],
                pdfName:'npu-profil'
            },
            {
                productID: 'product03',
                title: 'HEB PROFİL',
                content: 'HEB profil, yaygın olarak çelik yapı ve inşaat sektöründe kullanılan bir çelik profil çeşididir. HEB, "H-European Beams" kısaltmasından gelir',
                image: 'hep-profil',
                imagePage: 'urunlerimiz',
                url: 'heb-profil',
                subContent:'HEB profil, yaygın olarak çelik yapı ve inşaat sektöründe kullanılan bir çelik profil çeşididir. HEB, "H-European Beams" kısaltmasından gelir ve Avrupa standartlarına uygun olarak üretilir. Bu profiller, taşıyıcı kirişler, kolonlar, çerçeveler ve diğer yapısal elemanlar için idealdir.',
                property: [
                    'HEB profil, H şeklinde bir kesite sahiptir. Bu şekil, yüksek mukavemet ve taşıma kapasitesi sağlar.',
                    'HEB profiller, yüksek mukavemetli çelikten üretilir ve dayanıklıdır. Bu, çeşitli yapısal uygulamalarda güvenilir taşıma sağlar.',
                    'İnşaat projelerinde, özellikle büyük binaların taşıyıcı elemanlarında ve çerçevelerinde kullanılırlar.'
                ],
                pdfName:'heb-profil'
            },
            {
                productID: 'product04',
                title: 'HEA PROFİL',
                content: 'HEA demiri, çelik yapı malzemelerinden biridir ve Avrupa Profil Kesit Standardına (European Profile Section Standard) göre tanımlanır.',
                image: 'hea-profil',
                imagePage: 'urunlerimiz',
                url: 'hea-profil',
                subContent:'HEA demiri, çelik yapı malzemelerinden biridir ve Avrupa Profil Kesit Standardına (European Profile Section Standard) göre tanımlanır. HEA demiri, I şeklinde bir kesite sahiptir ve üst ve alt flanşlar arasındaki yükseklik genellikle genişliğin üçte biri kadardır. Bu demir çeşidi, yüksek mukavemeti ve taşıma kapasitesi nedeniyle çeşitli yapısal uygulamalarda kullanılır.',
                property: [
                    'Özellikle kolonlar, kirişler, çatı iskeletleri ve destek sistemleri gibi yapısal bileşenlerin yapımında tercih edilir.',
                    'Fabrikalar, gemi yapımı ve diğer endüstriyel tesislerde, HEA demiri, yapısal destek sistemlerinin yapımında kullanılır.',
                    'Modern ve endüstriyel tarzda dekorasyonlarda, açık yapısal elemanlar veya dekoratif detaylar olarak kullanılabilir.'
                ],
                pdfName:'hea-profil'
            },
            {
                productID: 'product05',
                title: 'İPE PROFİL',
                content: 'İPE demiri, "I" şeklinde bir kesite sahip olan ve genellikle çelik yapı malzemeleri arasında yer alan bir profil çeşididir. Üst ve alt flanşları birbirine bağlayan',
                image: 'ipe-profil',
                imagePage: 'urunlerimiz',
                url: 'ipe-profil',
                subContent:'İPE demiri, "I" şeklinde bir kesite sahip olan ve genellikle çelik yapı malzemeleri arasında yer alan bir profil çeşididir. Üst ve alt flanşları birbirine bağlayan dikey bir orta kısıma sahiptir. İPE demiri, genellikle Avrupa Profil Kesit Standardına (European Profile Section Standard) göre tanımlanır.',
                property: [
                    'İPE demiri, yüksek taşıma kapasitesi ve dayanıklılığı sayesinde büyük ölçekli taşıma sistemlerinde tercih edilir.',
                    'Taşıyıcı çerçeveler, makine montaj kısımları ve destek sistemleri gibi uygulamalarda sıklıkla tercih edilir.',
                    'Dayanıklı ve sağlam yapısı, denizcilik endüstrisindeki çeşitli uygulamalar için de uygun bir seçenek sağlar.'
                ],
                pdfName:'ipe'
            },
            {
                productID: 'product11',
                title: 'DOLU KARE',
                content: 'Kare dolu demir, genel montaj veya üretim için birçok farklı endüstride yaygın olarak kullanılan hadde ürünleridir. Kare dolu demir',
                image: 'dolu-kare',
                imagePage: 'urunlerimiz',
                url: 'dolu-kare',
                subContent:'Kare dolu demir, genel montaj veya üretim için birçok farklı endüstride yaygın olarak kullanılan hadde ürünleridir. Kare dolu demir her türlü malzemeden üretilebilen bir hadde ürünüdür. Mükemmel ağırlık taşıma özellikleri, yüksek korozyon direnci, üst seviye dayanıklılık bu ürünün diğer dikkat çeken özellikleridir.',
                property: [
                    'İnşaat sektöründe çeşitli yapı desteklerinin içerisinde kare dolu demirlere yer verilir.',
                    'Beton temellerde kare dolu demirlerin ağırlık taşıma özelliklerinden yararlanmak için kare dolu demirlerden yararlanılır.',
                    'Pencere ve kapı gibi çeşitli alanlarda korkulukların yapımında kare dolu demirler tercih edilir.'
                ],
                pdfName:'dolu-kare'
            },
            {
                productID: 'product07',
                title: 'LAMA',
                content: 'Lama demirler, inşaat sektöründe en sık kullanılan demir malzemelerdendir. Dikdörtgen biçiminde haddelenmiş olan lama demir.',
                image: 'lama',
                imagePage: 'urunlerimiz',
                url: 'lama',
                subContent:'Lama demirler, inşaat sektöründe en sık kullanılan demir malzemelerdendir. Dikdörtgen biçiminde haddelenmiş olan lama demir, bilinen en sağlam demir türlerinden biridir. Kullanım alanlarına sağlamış olduğu sağlamlık nedeniyle de her geçen gün daha yaygın hale gelmektedir.',
                property: [
                    'Lama demirler, sıcak çekme ve soğuk çekme işlemlerine de tabi tutuldukları için son derece dayanıklıdır.',
                    'Lama demirin kullanıldığı köprülerin yük çekme kapasitesi, farklı malzemelerden yapılmış köprülere kıyasla daha fazladır.',
                    'Genellikle güçlendirme malzemesi olarak kullanılır, paslanmaz yapısıyla uzun yıllar dayanıklılık gösterir.'
                ],
                pdfName:'lama-demir'
            },
            {
                productID: 'product08',
                title: 'SİLME LAMA',
                content: 'Silme lama, farklı boyutlardaki üretimi ile genellikle düz ve dikdörtgen hadde ürünlerdir. Genellikle inşaat demirlerinde kullanılan silme demirler',
                image: 'silme-lama',
                imagePage: 'urunlerimiz',
                url: 'silme-lama',
                subContent:'Silme lama, farklı boyutlardaki üretimi ile genellikle düz ve dikdörtgen hadde ürünlerdir. Genellikle inşaat demirlerinde kullanılan silme demirler çelik yapılarda altyapı görevini görürler. Ayrıca dayanıklı olması diğer sektörlerde de tercih edilmesine olanak sağlamaktadır.',
                property: [
                    'Silme lama  uzun ömürlü bir kullanım imkânı ve sağlamlığı sayesinde uygun maliyetler de yapılabilmektedir.',
                    'Otomotiv sektöründe yedek parça üretimi, iş makinelerinin ve tarım araçlarının üretiminde silme lama tercih edilmektedir.',
                    'Lama demirin silme lama kadar dayanıklı olabilmesi için daha fazla işlemden geçirilmesi gerekir bu da maliyetleri etkiler.'
                ],
                pdfName:'silme-demir'
            },
            {
                productID: 'product09',
                title: 'KÖŞEBENT',
                content: 'Köşebent demir, köşeleri dikdörtgen şeklinde olan ve genellikle çelikten imal edilen bir yapı malzemesidir. Köşebentler, L şeklindeki kesiti sayesinde',
                image: 'kosebent',
                imagePage: 'urunlerimiz',
                url: 'kosebent',
                subContent:'Köşebent demir, köşeleri dikdörtgen şeklinde olan ve genellikle çelikten imal edilen bir yapı malzemesidir. Köşebentler, L şeklindeki kesiti sayesinde çeşitli yapısal uygulamalarda kullanılır ve genellikle taşıyıcı elemanlar, çerçeveler, duvar köşeleri ve benzeri yapılarda kullanılır.',
                property: [
                    'L şeklinde bir kesite sahiptir. Bu kesit, yapısal uygulamalarda köşelerin güçlendirilmesi ve desteklenmesi için idealdir.',
                    'Çelikten imal edilen köşebent demir, yüksek mukavemet ve dayanıklılığa sahiptir.',
                    'Taşıyıcı kirişler, kolonlar, çerçeveler, duvar köşeleri ve benzeri yapısal elemanların imalatında ve montajında tercih edilir.'
                ],
                pdfName:'kosebant-demir'
            },
            {
                productID: 'product10',
                title: 'DOLU MİL',
                content: 'Yuvarlak dolu demir mil, genellikle yuvarlak kesitli ve düzgün bir yüzeye sahip olan demir çubuklardır. Bu mıllar, çeşitli endüstriyel uygulamalarda kullanılır.',
                image: 'dolu-mil',
                imagePage: 'urunlerimiz',
                url: 'dolu-mil',
                subContent:'Yuvarlak dolu demir mil, genellikle yuvarlak kesitli ve düzgün bir yüzeye sahip olan demir çubuklardır. Bu mıllar, çeşitli endüstriyel uygulamalarda kullanılarak mekanik parçaların desteklenmesi, dönme hareketi aktarılması veya yapısal stabilite sağlanması amacıyla kullanılır.',
                property: [
                    'İnşaat sektöründe ve imalat endüstrisinde, çeşitli yapısal parçaların desteklenmesi ve sabitlenmesinde kullanılır.',
                    'Çelikten imal edilen köşebent demir, yüksek mukavemet ve dayanıklılığa sahiptir.',
                    'Güçlü ve dayanıklı yapısından dolayı silah ve savunma sanayinde sıklıkla tercih edilirler.'
                ],
                pdfName:'dolu-mil'
            },
        ],
    },
    {
        category: 'insaat-demirleri',
        title: 'İnşaat Demirleri',
        url:'insaat-demirleri',
        products: [
            {
                productID: 'product01',
                title: 'ÇELİK HASIR',
                content: 'Çelik hasır, çelik telin kaynak veya örgü yöntemiyle belirli aralıklarla birbirine bağlanarak oluşturulan bir yapı malzemesidir. Genellikle yüksek mukavemetlidir.',
                image: 'celik-hasir',
                imagePage: 'urunlerimiz',
                url: 'boya',
                subContent:'Çelik hasır, çelik telin kaynak veya örgü yöntemiyle belirli aralıklarla birbirine bağlanarak oluşturulan bir yapı malzemesidir. Genellikle yüksek mukavemetli çelik tel kullanılarak üretilir ve çeşitli ölçülerde ve desenlerde mevcuttur. Çelik hasır, genellikle betonarme yapılarda donatı olarak kullanılır ve betonarme elemanların güçlendirilmesinde önemli bir rol oynar.',
                property: [
                    'Betonarme kolonlar, kirişler, döşemeler ve temeller gibi yapı elemanlarının güçlendirilmesinde kullanılır.',
                    'Prefabrik duvar panelleri, döşemeler ve diğer yapı elemanlarında çelik hasır kullanılarak yapıların dayanıklılığı sağlanır.',
                    'Özellikle toprak kaymalarını önlemek ve zeminin taşıma kapasitesini artırmak için kullanılır.'
                ],
                pdfName:'celik-hasir'
            },
            {
                productID: 'product02',
                title: 'İNŞAAT DEMİRİ',
                content: 'İnşaat demiri, inşaat sektöründe kullanılan çelik çubuklardır. Genellikle yüksek mukavemetli çelikten imal edilirler ve betonarme yapılardadır.',
                image: 'insaat-demir',
                imagePage: 'urunlerimiz',
                url: 'insaat-demir',
                subContent:'İnşaat demiri, inşaat sektöründe kullanılan çelik çubuklardır. Genellikle yüksek mukavemetli çelikten imal edilirler ve betonarme yapıların güçlendirilmesi ve dayanıklılığını artırmak için kullanılırlar. İnşaat demiri, çeşitli tiplerde ve ölçülerde bulunabilir ve genellikle betonarme kolonlar, kirişler, döşemeler, temeller ve duvarlar gibi yapı elemanlarının donatısında kullanılır.',
                property: [
                    'Temellerin dayanıklılığını ve taşıma kapasitesini artırmak için temel çukurlarına inşaat demiri döşenir.',
                    'İnşaat demiri, yapıların dayanıklılığını artırmak, çatlak oluşumunu önlemek ve yapı elemanlarına destek olarak kullanılır.',
                    'Yol ve köprü yapımında, betonarme tabliyelerin donatısında inşaat demiri kullanılır.'
                ],
                pdfName:'insaat-demiri-olcum'
            },
        ],
    },
    {
        category: 'diger',
        title: 'Diğer Çeşitler',
        url:'diger',
        products: [
            {
                productID: 'product07',
                title: 'BOARDEX',
                content: 'Boardex, genellikle hafif ve dayanıklı bir yapı malzemesi olan çimento esaslı bir levhadır. Çimento, cam lifleri ve diğer katkı maddelerinin birleşimiyle üretilir.',
                image: 'bordex',
                imagePage: 'urunlerimiz',
                url: 'boardex',
                subContent:'Boardex, genellikle hafif ve dayanıklı bir yapı malzemesi olan çimento esaslı bir levhadır. Çimento, cam lifleri ve diğer katkı maddelerinin birleşimiyle üretilir. Boardex, yüksek basınç ve sıcaklık altında preslenerek sertleştirilir ve genellikle birçok farklı inşaat uygulamasında kullanılır.',
                property: [
                    'Boardex levhalar, iç mekanlarda ve dış cephe duvarlarında kaplama malzemesi olarak kullanılır.',
                    'Yüzeyleri düzgün ve pürüzsüzdür, bu da boyanabilir veya kaplanabilir, dekoratif çözümler için uygundur.',
                    'Ofislerde, ticari alanlarda ve diğer iç mekanlarda bölme duvarları ve paravanlar için kullanılır.'
                ],
            },
            {
                productID: 'product08',
                title: 'OSB ÇEŞİTLERİ',
                content: 'OSB (Oriented Strand Board), odun yongalarının çapraz olarak yerleştirilmesiyle oluşturulan bir yapı malzemesidir. Genellikle 3 veya 4 katmandan oluşur',
                image: 'osb-cesitleri',
                imagePage: 'urunlerimiz',
                url: 'osb-cesitleri',
                subContent:'OSB (Oriented Strand Board), odun yongalarının çapraz olarak yerleştirilmesiyle oluşturulan bir yapı malzemesidir. Genellikle 3 veya 4 katmandan oluşur ve odun yongaları, bir bağlayıcı (genellikle fenolik reçine) ile birleştirilerek preslenir. OSB, yüksek sıcaklık ve basınç altında üretilir ve birçok farklı inşaat ve yapı uygulamasında kullanılır.',
                property: [
                    'OSB, inşaat panelleri, kalıp destekleri, duvarlar ve çatı kirişleri gibi farklı yapı ve inşaat malzemesinin üretiminde kullanılır.',
                    'OSB, iç ve dış duvar kaplamalarında ve tavan panellerinde kullanılabilir.',
                    ' İzolasyon malzemeleriyle birlikte kullanılarak yapıların dış cephelerini kaplamak ve korumak için tercih edilirler.'
                ],
            },
            {
                productID: 'product09',
                title: 'ALÇIPAN ÇEŞİTLERİ',
                content: 'Alçıpan, genellikle alçı ve kartonun birleştirilmesiyle oluşturulan bir yapı malzemesidir. Alçıpan levhaları, genellikle bir çerçeve üzerine monte edilir',
                image: 'alcipan-cesitleri',
                imagePage: 'urunlerimiz',
                url: 'alcipan-cesitleri',
                subContent:'Alçıpan, genellikle alçı ve kartonun birleştirilmesiyle oluşturulan bir yapı malzemesidir. Alçıpan levhaları, genellikle bir çerçeve üzerine monte edilir ve iç mekanlarda duvar ve tavan kaplaması olarak kullanılır. Çeşitli uygulamalarda kullanılan çok yönlü bir yapı malzemesidir.',
                property: [
                    'İç mekanlarda duvar kaplaması için kullanılır. Duvarlar düzgün ve pürüzsüz bir yüzey elde etmek için alçıpan kaplanır.',
                    'Özel şekillerde kesilebilir ve tasarlanabilirler, böylece iç mekanlarda estetik ve dekoratif dokunuşlar sağlar.',
                    ' İki katman arasına ses yalıtım malzemeleri yerleştirilerek ses geçişini azaltmaya yardımcı olurlar.'
                ],
            },
            {
                productID: 'product001',
                title: 'ATERMİT OLUKLU LEVHA',
                content: 'Atermit oluklu levha, genellikle fibercement malzemesinden üretilen ve çeşitli yapı ve inşaat uygulamalarında kullanılan bir tür yapı panelidir.',
                image: 'atermit-levha',
                imagePage: 'urunlerimiz',
                url: 'atermit-levha',
                subContent:'Atermit oluklu levha, genellikle fibercement malzemesinden üretilen ve çeşitli yapı ve inşaat uygulamalarında kullanılan bir tür yapı panelidir. Bu levhalar, genellikle dış cephe kaplamaları, çatı kaplamaları, duvar kaplamaları ve çit panelleri gibi çeşitli amaçlarla kullanılır.',
                property: [
                    'Hafif olmaları, dayanıklılıkları ve suya dayanıklı özellikleri nedeniyle çeşitli yapı tiplerinde kullanılabilirler.',
                    'Bu levhalar, dayanıklılıkları ve düşük bakım gereksinimi nedeniyle bahçe ve peyzaj projelerinde tercih edilirler.',
                    'Dayanıklı yapıları, yüksek sıcaklık ve kimyasal dirençleri sayesinde endüstriyel ortamlarda kullanıma uygundur.'
                ],
            },
            {
                productID: 'product002',
                title: 'ATERPAN FİBERCEMENT',
                content: 'Aterpan fibercement, fibercement malzemeden üretilen bir yapı panelidir. Bu panel, çimento, lifler ve katkı maddelerinin bir karışımından oluşur.',
                image: 'aterpan-levha',
                imagePage: 'urunlerimiz',
                url: 'aterpan-fibercement/kategoriler',
                subContent:'Atermit oluklu levha, genellikle fibercement malzemesinden üretilen ve çeşitli yapı ve inşaat uygulamalarında kullanılan bir tür yapı panelidir. Bu levhalar, genellikle dış cephe kaplamaları, çatı kaplamaları, duvar kaplamaları ve çit panelleri gibi çeşitli amaçlarla kullanılır.',
                property: [
                    'Hafif olmaları, dayanıklılıkları ve suya dayanıklı özellikleri nedeniyle çeşitli yapı tiplerinde kullanılabilirler.',
                    'Bu levhalar, dayanıklılıkları ve düşük bakım gereksinimi nedeniyle bahçe ve peyzaj projelerinde tercih edilirler.',
                    'Dayanıklı yapıları, yüksek sıcaklık ve kimyasal dirençleri sayesinde endüstriyel ortamlarda kullanıma uygundur.'
                ],
                subcategory:[
                    { title:'Çakıl Desenli Aterpan', image:'aterpan01', imagePage:'urunlerimiz', url:'' }, { title:'Çakıl Desenli Aterpan', image:'aterpan02', imagePage:'urunlerimiz', url:'' }, { title:'Çakıl Desenli Aterpan', image:'aterpan03', imagePage:'urunlerimiz', url:'' }, { title:'Ağaç Desenli Aterpan', image:'aterpan04', imagePage:'urunlerimiz', url:'' },
                    { title:'Ağaç Desenli Aterpan', image:'aterpan05', imagePage:'urunlerimiz', url:'' }, { title:'Ağaç Desenli Aterpan', image:'aterpan06', imagePage:'urunlerimiz', url:'' }, { title:'Kaya Desenli Aterpan', image:'aterpan07', imagePage:'urunlerimiz', url:'' }, { title:'Kaya Desenli Aterpan', image:'aterpan08', imagePage:'urunlerimiz', url:'' },
                    { title:'Kaya Desenli Aterpan', image:'aterpan09', imagePage:'urunlerimiz', url:'' }, { title:'Düz Aterpan', image:'aterpan10', imagePage:'urunlerimiz', url:'' }, { title:'Düz Aterpan', image:'aterpan11', imagePage:'urunlerimiz', url:'' }, { title:'Düz Aterpan', image:'aterpan12', imagePage:'urunlerimiz', url:'' },
                ]
            },
            {
                productID: 'product01',
                title: 'BOYA',
                content: 'Boya, yüzeyleri kaplamak, korumak veya renklendirmek için kullanılan bir malzemedir. Genellikle sıvı formda olan boyalar, bir yüzey üzerine uygulandıktan sonra kurur.',
                image: 'boya',
                imagePage: 'urunlerimiz',
                url: 'boya',
                subContent:'Boya, yüzeyleri kaplamak, korumak veya renklendirmek için kullanılan bir malzemedir. Genellikle sıvı formda olan boyalar, bir yüzey üzerine uygulandıktan sonra kuruyarak katı bir tabaka oluştururlar. Boyaların kullanım alanları oldukça geniştir ve çeşitli endüstrilerde ve uygulamalarda kullanılırlar.',
                property: [
                    'İnşaat sektöründe boyalar, iç ve dış mekan duvarları, tavanlar, dış cephe gibi yüzeylerin koruyucu kaplamalarında kullanılır.',
                    'Metal ve çelik yüzeylerin koruyucu kaplamaları, endüstriyel ekipmanların renklendirilmesi için çeşitli boyalar kullanılır.',
                    'Plastik malzemelerin renklendirilmesi ve korunması, ahşap mobilya, kapı gibi ürünlerin boyanması için de kullanılır.'
                ],
            },
            {
                productID: 'product02',
                title: 'DEKORATİF DEMİRLER',
                content: 'Dekoratif demir, estetik ve dekoratif amaçlar için tasarlanmış olan demir malzemelerdir. Genellikle çeşitli desenlerde, şekillerde ve detaylardadır.',
                image: 'dekoratif-demirler',
                imagePage: 'urunlerimiz',
                url: 'dekoratif-demirler',
                subContent:'Dekoratif demir, estetik ve dekoratif amaçlar için tasarlanmış olan demir malzemelerdir. Genellikle çeşitli desenlerde, şekillerde ve detaylarda üretilirler ve iç ve dış mekanlarda dekoratif amaçlar için kullanılırlar. Dekoratif demir ürünleri, yapıların estetik görünümünü artırmak, mekanlara karakter katmak ve görsel çekicilik sağlamak amacıyla kullanılır.',
                property: [
                    'Bahçe kapıları, çitler, pergolalar, süsleme elemanları ve benzeri ürünlerde dekoratif demir sıklıkla kullanılır.',
                    'Balkon korkulukları genellikle dekoratif demirden yapılır bu da mekanlara zarif bir görünüm kazandırır ve güvenliği artırır.',
                    'İç mekan mobilyalarında, lambaderlerde, avizelerde ve dekoratif aksesuarlarda dekoratif demir kullanılabilir.'
                ],
            },
            {
                productID: 'product03',
                title: 'KESME TAŞLARI',
                content: 'Kesme taşları, genellikle metal levha, çubuk veya boruların kesilmesi için kullanılan disk şeklindeki kesme araçlarıdır.Bu diskler, çeşitli metal malzemelerdir.',
                image: 'kesme-taslari',
                imagePage: 'urunlerimiz',
                url: 'kesme-taslari',
                subContent:'Kesme taşları, genellikle metal levha, çubuk veya boruların kesilmesi için kullanılan disk şeklindeki kesme araçlarıdır. Bu diskler, çeşitli metal malzemeleri hızlı ve hassas bir şekilde kesmek için tasarlanmıştır. Metal kesme diskleri çeşitli çaplarda ve kalınlıklarda üretilir ve çeşitli kesme işlemlerinde kullanılabilirler.',
                property: [
                    'İnşaat sektöründe metal profillerin, boruların ve levhaların kesilmesinde sıkça kullanılır.',
                    'Metal işleme tesislerinde, metal kesme diskleri, çeşitli metal parçaların işlenmesinde ve imalatında kullanılır.',
                    'Otomotiv sektöründe, metal kesme diskleri, araç parçalarının imalatında ve montajında kullanılır.'
                ],
            },
            {
                productID: 'product04',
                title: 'HIRDAVAT ÇEŞİTLERİ',
                content: 'Hırdavat, genellikle ev, bahçe, inşaat ve endüstriyel işlerde kullanılan çeşitli el aletleri, donanımlar, bağlantı elemanları ve diğer küçük ekipmanların genel adıdır.',
                image: 'hirdavat-cesitleri',
                imagePage: 'urunlerimiz',
                url: 'hirdavat-cesitleri',
                subContent:'Hırdavat, genellikle ev, bahçe, inşaat ve endüstriyel işlerde kullanılan çeşitli el aletleri, donanımlar, bağlantı elemanları ve diğer küçük ekipmanların genel adıdır. Bu araçlar ve malzemeler, çeşitli işlerde kullanılarak montaj, onarım, inşaat ve bakım gibi işlemleri gerçekleştirmek için kullanılır.',
                property: [
                    'Hırdavat ürünleri, yapı malzemelerinin montajı ve onarımı için kullanılır.',
                    'Otomobil tamir atölyelerinde ve bakım merkezlerinde, hırdavat ürünleri araçların onarımı ve bakımı için kullanılır.',
                    'Endüstriyel matkaplar, kesme aletleri, kaynak makineleri, el aletleri, endüstriyel işlerde yaygın olarak kullanılan ürünleridir.'
                ],
            },
            {
                productID: 'product05',
                title: 'ELEKTROT',
                content: 'Elektrot, metal bir telin bir ucu birleştirilmiş ve bir kaplama ile kaplanmış, diğer ucu ise açık bırakılmış bir elektrik iletkenidir. Kaynak işleminde kullanılırlar.',
                image: 'elektrot',
                imagePage: 'urunlerimiz',
                url: 'elektrot',
                subContent:'Elektrot, metal bir telin bir ucu birleştirilmiş ve bir kaplama ile kaplanmış, diğer ucu ise açık bırakılmış bir elektrik iletkenidir. Kaynak işleminde kullanılan temel araçlardan biridir. Elektrik ark kaynağı (EAK) veya gaz altı kaynak gibi farklı kaynak yöntemlerinde kullanılır. Elektrotlar, elektrodlar olarak da adlandırılır ve çeşitli tipleri ve kaplamaları mevcuttur.',
                property: [
                    'Elektrotlar, lehimleme işleminde lehimlenmiş parçaların bağlanmasına yardımcı olmak için kullanılabilir.',
                    'Elektrotlar, genellikle çelik, alüminyum, bakır ve demir gibi metal parçaların birleştirilmesinde kullanılır.',
                    'Kaynak işlemi sırasında elektrot, eriyen metal birleştirme işlemine katkı sağlar ve iki parçayı birleştirir.'
                ],
            },
            {
                productID: 'product06',
                title: 'MEBRAN',
                content: 'Membran, genellikle esnek ve dayanıklı bir malzemeden yapılmış, su geçirmez ve su buharı geçirgen özelliklere sahip bir yapı malzemesidir.',
                image: 'mebran',
                imagePage: 'urunlerimiz',
                url: 'mebran',
                subContent:'Membran, genellikle esnek ve dayanıklı bir malzemeden yapılmış, su geçirmez ve su buharı geçirgen özelliklere sahip bir yapı malzemesidir. Membranlar, genellikle yapıların su yalıtımı, havalandırma, ısı yalıtımı ve koruyucu kaplamalarında kullanılır. Dayanıklılık ve uzun ömürlü kullanım gibi özellikler membranları yapı sektöründe önemli bir malzeme haline getirir.',
                property: [
                    'Membranlar, binaların çatıları, terasları, bodrumları ve diğer suya maruz kalan bölgelerinde su yalıtımı için kullanılır.',
                    'Bina cephesi ve çatılarında kullanılarak su buharının dışarı atılmasına, nemin kontrol altında tutulmasına yardımcı olur.',
                    'Yalıtım malzemesi olarak membranlar, ısı transferini azaltarak binaların enerji verimliliğini artırır.'
                ],
            },
            { productID: 'product010', title: 'İspiral Makinesi' ,},{ productID: 'product011', title: 'Matkap',},{ productID: 'product012', title: 'Daire Testere',},{ productID: 'product013', title: 'Aşındırıcı Taşlar',} ,{ productID: 'product014', title: 'Kesici Taşlar',},
            { productID: 'product15', title: 'Elektrotlar' ,},{ productID: 'product016', title: 'Menteşeler',},{ productID: 'product017', title: 'Ferforje Sürgüler',},{ productID: 'product018', title: 'Denge Bilyeleri',} ,{ productID: 'product019', title: 'Boya, Antipas, Tiner',},
            { productID: 'product20', title: 'Kaynak Makineleri' ,},{ productID: 'product21', title: 'Kaynak Maskesi',},{ productID: 'product22', title: 'Endüstriyel Gaz Maskeleri',},{ productID: 'product23', title: 'Eldiven',} ,{ productID: 'product24', title: 'Vida Çeşitleri',},
            { productID: 'product25', title: 'Somun Çeşitleri' ,},{ productID: 'product26', title: 'Çivi Çeşitleri',},{ productID: 'product27', title: 'Kapı Kilitleri',},{ productID: 'product27', title: 'Demirci Sürgüleri',} ,{ productID: 'product28', title: 'PU Köpük',},
            { productID: 'product29', title: 'Silikon' ,},{ productID: 'product30', title: 'Pense',},{ productID: 'product31', title: 'Şerit Metre',},{ productID: 'product32', title: 'Koruyucu Gözlük',} ,{ productID: 'product33', title: 'Zımparalar',},
            { productID: 'product34', title: 'Ayarlı Çarpma' ,},{ productID: 'product35', title: 'Sanayi Tekerlekleri',},{ productID: 'product36', title: 'Sac Kelepçeler',},{ productID: 'product37', title: 'Rondela ve Pullar',} ,{ productID: 'product38', title: 'Şaryo Makaralar',},  
            { productID: 'product39', title: 'Şaloma ve Ekipmanları' ,},{ productID: 'product40', title: 'Şaloma Hortumu',},{ productID: 'product41', title: 'Basınç Saatleri',},{ productID: 'product42', title: 'Testere Bıçakları',} ,{ productID: 'product43', title: 'Gazlı Kaynak Telleri',},
            { productID: 'product44', title: 'Ray Makaraları' ,},{ productID: 'product45', title: 'Cıvata Çeşitleri',},{ productID: 'product46', title: 'Kapı Tokmakları',},{ productID: 'product47', title: 'Spanzet',} ,{ productID: 'product48', title: 'Palet Streç Filmi',},
            { productID: 'product49', title: 'Çatı Bağlantı Elemanları' ,},{ productID: 'product45', title: 'Membran',}
        ],
    }
];

export default ProductData;