import { ReactSVG } from 'react-svg'


const ChangeSVG = ({ name, width, height, color }) => {

    const pathSVG = require(`../assets/svg/${name}.svg`);

    if (!pathSVG) {
        return <div>Hata!</div>;
    }

    return (
        <ReactSVG src={pathSVG} style={{ fill: color, width: width, height: height }} />
    );

}

export default ChangeSVG;


