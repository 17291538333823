import UserFooter from "./UserFooter";
import UserHeader from "./UserHeader";



const UserLayout = ({ children }) => {

    return (
        <>
            <UserHeader />
            {children}
            <UserFooter />
        </>
    )
}

export default UserLayout;