import blogData from '../assets/data/blog';
import { useParams } from 'react-router-dom';
import navBackground from '../assets/images/blog/blog.png'
import ChangeIMG from '../helpers/ChangeIMG';
import { Helmet } from 'react-helmet';

const BlogDetay = () => {

    const { id } = useParams();

    const blog = blogData.find(blog => blog.url === id);


    return (
        <main>

            <Helmet>
                <title>{blog.title}</title>
                <meta name="description" content={`${blog.content}`} />
            </Helmet>


            <div className='relative'>
                <img className='object-cover w-full h-32' src={navBackground} alt="" />
                <div className="absolute top-0 bottom-0 bg-black bg-opacity-65 start-0 end-0"></div>
            </div>

            <section>
                <article className='container'>
                    <div className="flex flex-col py-20 lg:flex-row gap-9">
                        <div className="basis-9/12">
                            <div className="p-5 bg-white blogCard">
                                <div className="text-2xl font-black">{blog.title}</div>
                                <div className='h-[28.125rem] overflow-hidden mt-9 rounded-md'>
                                    <ChangeIMG page='blog' name={blog.image} alt={blog.title} />
                                </div>
                                <div className='my-9'>{blog.content}</div>
                                {
                                    blog.longContent.map((content, index) => (
                                        <div key={index}>
                                            <div className='mt-6 mb-3 font-black'>{content.title}</div>
                                            {content.content}
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                        <div className="basis-3/12">
                            <div className="p-5 bg-white rightCard">
                                <div className='flex items-center gap-3'>
                                    <div className="w-1 h-7 bg-[#CE2925]"></div>
                                    <span className='text-xl font-bold'>Son Gönderiler</span>
                                </div>
                                <div className="div">
                                    <a href='/blog/profil-turleri' className="flex items-center mt-5">
                                        <div className="flex items-center gap-6">
                                            <div className="basis-6/12">
                                                <ChangeIMG page='blog' name='blog01' rounded='md' />
                                            </div>
                                            <div className="basis-6/12">
                                                <span className='text-xs font-bold'>Profil Türleri ve Kullanım Alanları</span>
                                            </div>
                                        </div>
                                    </a>
                                    <div className="my-6 mb-6 border border-black opacity-10"></div>
                                    <a href='/blog/sandvic-panel' className="flex items-center mt-5">
                                        <div className="flex items-center gap-6">
                                            <div className="basis-6/12">
                                                <ChangeIMG page='blog' name='blog02' rounded='md' />
                                            </div>
                                            <div className="basis-6/12">
                                                <span className='text-xs font-bold'>Yapı Sektöründe Sandviç Panel</span>
                                            </div>
                                        </div>
                                    </a>
                                    <div className="my-6 mb-6 border border-black opacity-10"></div>
                                    <a href='/blog/hirdavat-ve-demir-celik-bakimi' className="flex items-center mt-5">
                                        <div className="flex items-center gap-6">
                                            <div className="basis-6/12">
                                                <ChangeIMG page='blog' name='blog03' rounded='md' />
                                            </div>
                                            <div className="basis-6/12">
                                                <span className='text-xs font-bold'>Hırdavat ve Demir-Çelik Bakımı</span>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div className="p-5 mt-5 bg-[#F4F4F4] rightToCard border border-black border-opacity-5">
                                <div className='flex items-center gap-3'>
                                    <div className="w-1 h-7 bg-[#CE2925]"></div>
                                    <span className='text-xl font-bold'>Ürünler Hakkında</span>
                                </div>
                                <p className='text-sm mt-7'>Ürün ve ürün gruplarımız hakkında daha detaylı bilgi alın.</p>
                                <div className='flex justify-end mt-7'>
                                    <a className='py-2 px-3 bg-[#CE2925] text-white text-sm rounded-md' href="/iletisim">Bizimle İletişime Geçin</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>
            </section>


        </main>
    )
}

export default BlogDetay;