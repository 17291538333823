
import HalfBanner from '../components/HalfBanner';
import blogData from '../assets/data/blog';
import ChangeIMG from '../helpers/ChangeIMG';
import ChangeSVG from '../helpers/ChangeSVG';
import { Helmet } from 'react-helmet';

const Blog = () => {
    return (
        <main>
            <Helmet>
                <title>Güzeltepe - Blog</title>
                <meta name="description" content="Güzeltepe'nin blog sayfasında inşaat ve yapı malzemeleriyle ilgili güncel bilgiler, sektör trendleri, projelerimiz hakkında haberler ve ipuçları bulabilirsiniz. Blog yazılarımızı okuyarak sektördeki yenilikleri ve Güzeltepe'nin uzmanlığını keşfedin." />
            </Helmet>

            <HalfBanner page={'BLOGLARIMIZ'} />

            <section>
                <article className='container py-20'>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-9">
                        {
                            blogData.map((blog, index) => (
                                <div key={index} className="overflow-hidden bg-white rounded-md card">
                                    <ChangeIMG page='blog' name={blog.image} alt='blog' />
                                    <div className='p-5'>
                                        <div className='mb-3 text-lg font-black'>{blog.title}</div>
                                        <div className="mb-6 border border-black opacity-10"></div>
                                        <div className='text-sm line-clamp-3'>{blog.content}</div>
                                        <div className='flex justify-end mt-9'>
                                            <a className='flex items-center gap-3 text-sm text-[#D22027] font-bold' href={`/blog/${blog.url}`}>
                                                <span>Devamını Oku</span>
                                                <ChangeSVG name='arrow' width={16} height={16} color='#D22027' />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </article>
            </section>

        </main>
    )
}

export default Blog;