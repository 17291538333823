import ChangeIMG from "../helpers/ChangeIMG";
import HalfBanner from '../components/HalfBanner';
import ChangeSVG from "../helpers/ChangeSVG";
import { Helmet } from 'react-helmet';

const Hakkimizda = () => {
    return (
        <main>
            <Helmet>
                <title>Güzeltepe - Hakkımızda</title>
                <meta name="description" content="Güzeltepe, inşaat ve yapı malzemeleri sektöründe uzun yıllara dayanan deneyimi ve kaliteli hizmet anlayışıyla müşterilerine en iyi hizmeti sunmayı amaçlamaktadır. Sektördeki lider konumunu güçlü referansları ve memnun müşterileriyle sağlamaktadır." />
            </Helmet>

            <HalfBanner page={'HAKKIMIZDA'} />

            <section className="py-20">
                <article className="container relative">
                    <div className="flex flex-col md:flex-row">
                        <div className="basis-1/2">
                            <ChangeIMG page='hakkimizda' name='hakkimizdaguzeltepe' rounded='xl' />
                        </div>
                        <div className="basis-1/2"></div>
                    </div>
                    <div className="absolute top-0 bottom-0 right-0 w-full h-full mt-64 md:mt-0">
                        <div className="container">
                            <div className="flex flex-col md:flex-row">
                                <div className="basis-5/12"></div>
                                <div className="basis-7/12">
                                    <div className="py-8">
                                        <div className="relative flex flex-col items-start justify-start py-5 bg-white rounded-md pe-10 ps-6">
                                            <span className="text-[#D22027] font-black">Hakkımızda</span>
                                            <h2 className="mt-4 text-2xl font-black mb-7">Demir Çelik Sektöründe, Etkili Çözümler Sunarak Sektörün İhtiyaçlarına Çözüm Oluyoruz.</h2>
                                            <p className="text-sm opacity-50">Güzeltepe Metal olarak, geniş bir ürün yelpazesi ile hizmet veriyoruz. Demir-çelik, sac, profil, panel, hırdavat çeşitleri gibi sektörde ihtiyaç duyulan birçok ürünü müşterilerimize sunuyoruz. Güçlü markaların yetkili bayisi olarak, sektördeki uzmanlığımızı her geçen gün daha da pekiştiriyoruz.</p>
                                            <div className="mt-6 font-black">Güçlü İş Ortaklıkları</div>
                                            <p className="mb-2 text-sm opacity-50 mt-7">Güzeltepe Metal, sektördeki lider markalar ile kurduğu güçlü iş ortaklıkları sayesinde müşterilerine en kaliteli ürünleri sunmaktadır. Bu işbirlikleri, müşterilerimize geniş bir ürün yelpazesi ve sektörel uzmanlık sunmamıza olanak tanımaktadır.</p>
                                            <div className="absolute top-0 bottom-0 right-0">
                                                <div className="bg-[#D22027] h-full w-2"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>
            </section>

            <section>
                <article className="container pt-[650px] md:pt-[190px] lg:pt-0">
                    <div className="bg-white rounded-md p-9">
                        <div className="flex flex-col items-center gap-12 lg:flex-row">
                            <div className="flex flex-col gap-6 basis-1/2">
                                <div className="flex flex-col items-center gap-6 lg:flex-row">
                                    <div className="basis-1/2">
                                        <ChangeIMG page='hakkimizda' name='kalite01' rounded='md' />
                                    </div>
                                    <div className="basis-1/2">
                                        <ChangeSVG name='kalite01' width={56} height={56} color='#B2B2B2' />
                                        <span className="flex my-4 font-black">VİZYONUMUZ</span>
                                        <p className="text-sm text-justify">Demir çelik sektöründe lider konumumuza gelişen teknolojileri entegre ederek güçlendirmek. Sürdürülebilir teknoloji kullanımıyla en ileri teknolojileri sunarak sektörde fark yaratmak istiyoruz.</p>
                                    </div>
                                </div>
                                <div className="flex flex-col items-center gap-6 lg:flex-row">
                                    <div className="mt-6 lg:order-1 basis-1/2 lg:mt-0">
                                        <ChangeIMG page='hakkimizda' name='kalite02' rounded='md' />
                                    </div>
                                    <div className="basis-1/2 ">
                                        <ChangeSVG name='kalite02' width={64} height={64} color='#B2B2B2' />
                                        <span className="flex my-4 font-black">MİSYONUMUZ</span>
                                        <p className="text-sm text-justify">Demir çelik sektöründe müşterilerimize en kaliteli ürünleri sağlamak ve sektöre değer katmak üzerine odaklanmaktadır. Sektörde liderliğimizi sürdürmeyi ve yüksek etik standartlarımızı korumayı misyonumuzun temelini oluşturmaktadır.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="basis-1/2">
                                <h3 className="flex flex-col gap-5 text-4xl font-black">
                                    <p>Demir Çelikte</p>
                                    <p className="text-[#D22027]">Güzeltepe Metal</p>
                                    <p>Güven ve Kalitenin Adı</p>
                                </h3>
                                <div className="w-3/4 bg-[#D22027] h-1 my-9"></div>
                                <p className="mt-9 text-[#7A7A7A] text-sm text-justify">Güvenin ve kalitenin simgesi olarak, Güzeltepe Metal, demir çelik sektöründe müşterilerine en üst düzeyde hizmet sunmaktadır. Firmamız, sektöre özgü ihtiyaçlara yönelik çözümlerle, güçlü tedarikçi ilişkileri ve uzman ekibiyle müşterilerine güvenilir bir iş ortağı olma ayrıcalığını yaşatıyor. </p>
                                <p className="mt-9 text-[#7A7A7A] text-sm text-justify">Her bir müşterimizin özel ihtiyaçlarına odaklanarak, kalite standartlarımızı yüksek tutuyor ve sektörde lider markaların yetkili bayisi olarak güçlü bir konumda bulunuyoruz.</p>
                                <div className="flex justify-start mt-12">
                                    <a className="py-3 bg-[#D22027] px-7 text-white font-bold text-xs rounded-md" href="/iletisim" >Hakkımızda Daha Fazlasını Öğrenin</a>
                                </div>

                            </div>
                        </div>
                    </div>
                </article>
            </section>

            <section className="py-16">
                <article className="container relative">
                    <div className="flex flex-col items-end overflow-hidden bg-white rounded-md md:flex-row">
                        <div className="basis-4/12">
                            <ChangeIMG page='hakkimizda' name='celik' width='auto' />
                        </div>
                        <div className="relative pb-6 pr-10 basis-8/12">
                            <p className="text-2xl font-black lg:text-3xl text-end mt-9 lg:mt-0">İhtiyaçlarınıza Yönelik Özel Çözümler</p>
                            <p className="mt-8 text-sm opacity-75">Güzeltepe Metal olarak, işinizi kolaylaştırmak, özel ihtiyaçlarınıza yönelik en uygun çelik ve demir çözümlerini sunmak için buradayız. Müşteri memnuniyetini ön planda tutarak, sektöre özgü taleplerinizi karşılamak ve beklentilerinizi aşmak için uzman ekibimizle birlikte çalışıyoruz. Size özel projeleriniz ve gereksinimleriniz için en etkili çözümleri sunmak adına bize ulaşın.</p>
                            <div className="flex justify-start mt-8">
                                <a className="py-3 bg-[#D22027] px-7 text-white font-bold text-xs rounded-md" href="/iletisim" >Bizimle İletişime Geçin</a>
                            </div>
                            <div className="absolute top-0 bottom-0 right-0 z-20">
                                <div className="w-2 h-full bg-[#D22027]"></div>
                            </div>
                            <div className="absolute bottom-0 right-0 z-20">
                                <div className="w-56 h-2 bg-[#D22027]"></div>
                            </div>
                        </div>
                    </div>
                </article>
            </section>

        </main>
    )
}

export default Hakkimizda;