import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';

import 'swiper';

const SwiperSlider = ({ slides, modules = [Navigation, Pagination, Scrollbar, Autoplay], allowTouchMove = true, prev = '.swiper-button-prev', next = '.swiper-button-next', padding = 0, centered = false, centeredBounds = false, spaceBetween = 0, slideView = 1, initialSlide = 0, loop = false, delay = 2000, speed = 1000, StartView = 1, direction = 'horizontal', height = null }) => {



    const py = `py-[${padding}px]`

    return (
        <Swiper
            style={{ height: height }}
            modules={modules}
            direction={direction}
            centeredSlides={centered}
            centeredSlidesBounds={centeredBounds}
            spaceBetween={spaceBetween}
            slidesPerView={slideView}
            initialSlide={initialSlide}
            navigation={{
                prevEl: prev,
                nextEl: next,
            }}
            loop={loop}
            autoplay={{
                delay: delay,
                disableOnInteraction: false,
                speed: speed
            }}
            allowTouchMove={allowTouchMove}
            pagination={{ clickable: false }}
            breakpoints={{
                768: {
                    slidesPerView: slideView,
                },
                0: {
                    slidesPerView: StartView,
                },
            }}
        >
            {
                slides.map((slide, index) => (
                    <SwiperSlide className={py} key={index}>{slide}</SwiperSlide>
                ))
            }
        </Swiper>
    );
};

export default SwiperSlider;