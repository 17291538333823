
import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import HalfBanner from '../components/HalfBanner';
import ChangeSVG from '../helpers/ChangeSVG';
import ProductData from '../assets/data/urunler';
import ChangeIMG from '../helpers/ChangeIMG';
import { Helmet } from 'react-helmet';


const AltUrunlerimiz = () => {

    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);



    const { category, subcategory, id } = useParams();

    const categoryData = ProductData.find(product => product.url === category);

    if (!categoryData) {
        return <div>Belirtilen kategori bulunamadı.</div>;
    }

    const item = categoryData.products.find(product => product.url === `${subcategory}/${id}`);

    if (!item) {
        return <div>Belirtilen alt kategori veya ürün bulunamadı.</div>;
    }

    if (!categoryData) {
        return <div>Belirtilen kategori veya ürün bulunamadı.</div>;
    }





    return (
        <main>
            <Helmet>
                <title>Güzeltepe - Alt Ürünlerimiz</title>
                <meta name="description" content="Güzeltepe, Alt Ürünlerimiz hakkında detaylı bilgi almak için sayfamızı ziyaret edin." />
            </Helmet>

            <HalfBanner page={'ÜRÜNLERİNİZ'} />

            <section className='py-20'>
                <div className='container'>
                    <div className="flex flex-col lg:flex-row gap-9">
                        <div className="basis-3/12">
                            <span className='text-[#D22027] font-extrabold'>Ürün Grupları</span>
                            <div className="px-4 py-6 mt-5 bg-white rounded-md card">
                                <div className="flex items-center justify-between opacity-50">
                                    <span>Tüm Kategoriler</span>
                                    <span>6</span>
                                </div>
                                <div className='flex flex-col gap-4 mt-4'>
                                    <a href="/urunlerimiz/profil-borular" className={`${pathname === '/urunlerimiz/profil-borular' ? 'bg-[#D22027] text-white fill-white' : 'bg-[#F0F0F0]'} flex items-center py-2 px-4 gap-3 rounded-md hover:bg-[#D22027] hover:text-white duration-300 group`}>
                                        <div className='group-hover:fill-white'>
                                            <ChangeSVG name='bar' width={20} height={20} />
                                        </div>
                                        <span className='text-sm font-medium'>Profil ve Borular</span>
                                    </a>
                                    <a href="/urunlerimiz/sac-cesitleri" className={`${pathname === '/urunlerimiz/sac-cesitleri' ? 'bg-[#D22027] text-white fill-white' : 'bg-[#F0F0F0]'} flex items-center py-2 px-4 gap-3 rounded-md hover:bg-[#D22027] hover:text-white duration-300 group`}>
                                        <div className='group-hover:fill-white'>
                                            <ChangeSVG name='sac' width={20} height={20} />
                                        </div>
                                        <span className='text-sm font-medium'>Sac Çeşitleri</span>
                                    </a>
                                    <a href="/urunlerimiz/panel-cesitleri" className={`${pathname === '/urunlerimiz/panel-cesitleri' ? 'bg-[#D22027] text-white fill-white' : 'bg-[#F0F0F0]'} flex items-center py-2 px-4 gap-3 rounded-md hover:bg-[#D22027] hover:text-white duration-300 group`}>
                                        <div className='group-hover:fill-white'>
                                            <ChangeSVG name='panel' width={20} height={20} />
                                        </div>
                                        <span className='text-sm font-medium'>Panel Çeşitleri</span>
                                    </a>
                                    <a href="/urunlerimiz/profiller" className={`${pathname === '/urunlerimiz/profiller' ? 'bg-[#D22027] text-white fill-white' : 'bg-[#F0F0F0]'} flex items-center py-2 px-4 gap-3 rounded-md hover:bg-[#D22027] hover:text-white duration-300 group`}>
                                        <div className='group-hover:fill-white'>
                                            <ChangeSVG name='npi' width={20} height={20} />
                                        </div>
                                        <span className='text-sm font-medium'>Hadde Ürünleri</span>
                                    </a>
                                    <a href="/urunlerimiz/insaat-demirleri" className={`${pathname === '/urunlerimiz/insaat-demirleri' ? 'bg-[#D22027] text-white fill-white' : 'bg-[#F0F0F0]'} flex items-center py-2 px-4 gap-3 rounded-md hover:bg-[#D22027] hover:text-white duration-300 group`}>
                                        <div className='group-hover:fill-white'>
                                            <ChangeSVG name='demir' width={20} height={20} />
                                        </div>
                                        <span className='text-sm font-medium'>İnşaat Demirleri</span>
                                    </a>
                                    <a href="/urunlerimiz/diger" className={`${pathname === '/urunlerimiz/diger' ? 'bg-[#D22027] text-white fill-white' : 'bg-[#F0F0F0]'} flex items-center py-2 px-4 gap-3 rounded-md hover:bg-[#D22027] hover:text-white duration-300 group`}>
                                        <div className='group-hover:fill-white'>
                                            <ChangeSVG name='arac' width={20} height={20} />
                                        </div>
                                        <span className='text-sm font-medium'>Diğer Ürünlerimiz</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="basis-9/12">
                            <div className="grid grid-cols-1 gap-3 md:grid-cols-3">
                                {
                                    item.subcategory.map((product, index) => (
                                        <div className='p-3 bg-white border border-black rounded-md border-opacity-10' key={index}>
                                            {product.image && (
                                                <div>
                                                    <ChangeIMG page={product.imagePage} name={product.image} alt={product.title} rounded='md' />
                                                    <p className={`${product.image ? 'mt-6' : ''} font-black`}>{product.title}</p>
                                                    <p className='mt-3 text-xs opacity-50 line-clamp-3'>{product.content}</p>
                                                    {
                                                        product.url &&
                                                        <div className='flex justify-end mb-3 mt-9 me-3'>
                                                            <a className='flex items-center gap-1 text-xs text-[#D22027] font-bold' href={`/urunlerimiz/${category}/${item.url}/${product.url}`}>
                                                                <span>Ürünü İnceleyin</span>
                                                                <ChangeSVG name='arrow' width={12} height={12} color='#D22027' />
                                                            </a>
                                                        </div>
                                                    }
                                                </div>
                                            )}
                                            {!product.image && (
                                                <p className={`font-medium text-sm`}>{product.title}</p>
                                            )}
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    )
}

export default AltUrunlerimiz;