import './assets/css/tailwindconfig.css'
import './assets/css/main.css'
import './assets/css/responsive.css'

// React Library

import ReactDOM from 'react-dom/client';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

// Layouts

import UserLayout from './layouts/UserLayout';

// Pages
import Home from './pages/Home';
import ErrorPage from './pages/ErrorPage';
import Hakkimizda from './pages/Hakkimizda';
import Urunlerimiz from './pages/Urunlerimiz';
import UrunDetay from './pages/UrunDetay';
import Referanslar from './pages/Referanslar';
import Blog from './pages/Blog';
import BlogDetay from './pages/BlogDetay';
import Iletisim from './pages/Iletisim';
import AltUrunlerimiz from './pages/AltUrunlerimiz';
import DipUrunlerimiz from './pages/DipUrunlerimiz';
import DipUrunDetay from './pages/DipUrunDetay';

const App = () => {
  return (
    <Routes>

      <Route path='/' element={<UserLayout><Home /></UserLayout>}></Route>

      <Route path='/sayfa-bulunamadi' element={<UserLayout><ErrorPage /></UserLayout>}></Route>
      <Route path='*' element={<Navigate to="/sayfa-bulunamadi" />}></Route>

      <Route path='/hakkimizda' element={<UserLayout><Hakkimizda /></UserLayout>}></Route>
      <Route path='/urunlerimiz/:category' element={<UserLayout><Urunlerimiz /></UserLayout>}></Route>
      <Route path='/urunlerimiz/:category/:id' element={<UserLayout><UrunDetay /></UserLayout>}></Route>

      <Route path='/urunlerimiz/:category/:subcategory/:id' element={<UserLayout><AltUrunlerimiz /></UserLayout>}></Route>
      <Route path='/urunlerimiz/:category/:subcategory/:id/:product' element={<UserLayout><DipUrunlerimiz /></UserLayout>}></Route>

      <Route path='/urunlerimiz/:category/:subcategory/:id/:product/:item' element={<UserLayout><DipUrunDetay /></UserLayout>}></Route>

      <Route path='/referanslar' element={<UserLayout><Referanslar /></UserLayout>}></Route>
      <Route path='/blog' element={<UserLayout><Blog /></UserLayout>}></Route>
      <Route path='/blog/:id' element={<UserLayout><BlogDetay /></UserLayout>}></Route>

      <Route path='/iletisim' element={<UserLayout><Iletisim /></UserLayout>}></Route>

    </Routes>
  )
}

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

export default App;

