
import HalfBanner from '../components/HalfBanner';
import ChangeSVG from '../helpers/ChangeSVG';
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { Helmet } from 'react-helmet';

const Iletisim = () => {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm('service_lf1dgjm', 'template_01', form.current, {
                publicKey: 'ZNu9u0nC_VEpzRdct',
            })
            .then(
                () => {
                    alert("Mailiniz Başarıyla Gönderildi");
                },
                (error) => {
                    alert("Mail Gönderilemedi!");
                },
            );
    };

    // const [name, setName] = useState("");
    // const [email, setEmail] = useState("");
    // const [phone, setPhone] = useState("");
    // const [message, setMessage] = useState("");

    // const sendEmail = async (e) => {
    //     e.preventDefault();
    //     let dataSend = {
    //         name: name,
    //         email: email,
    //         phone: phone,
    //         message: message,
    //     };

    //     try {
    //         const res = await fetch('http://localhost:8000/sendEmail', {
    //             method: "POST",
    //             body: JSON.stringify(dataSend),
    //             headers: {
    //                 Accept: "application/json",
    //                 "Content-Type": "application/json",
    //             },
    //         });

    //         if (res.ok) {
    //             alert("Mailiniz Başarıyla Gönderildi");
    //         } else {
    //             alert("Mail Gönderilemedi!");
    //         }
    //     } catch (error) {
    //         console.error("Error sending email:", error);
    //         alert("Mail Gönderilemedi!");
    //     }
    // };

    // const handleNameChange = (e) => setName(e.target.value);
    // const handleEmailChange = (e) => setEmail(e.target.value);
    // const handlePhoneChange = (e) => setPhone(e.target.value);
    // const handleMessageChange = (e) => setMessage(e.target.value);



    return (
        <main>

            <Helmet>
                <title>Güzeltepe - İletişim</title>
                <meta name="description" content="Güzeltepe'nin iletişim sayfası üzerinden bize ulaşabilirsiniz. İhtiyaçlarınızı, taleplerinizi ve geri bildirimlerinizi bizimle paylaşın. Size en iyi hizmeti sunabilmek için buradayız." />
            </Helmet>

            <HalfBanner page={'İLETİŞİM'} />

            <section className='py-10'>
                <article className='container'>
                    <div className="flex flex-col md:items-center md:flex-row gap-9">
                        <div className="basis-6/12">
                            <div className="bgCard bg-[#D22027] relative px-5 py-12 text-white rounded-md overflow-hidden">
                                <div className='relative z-10'>
                                    <span className='text-xl font-black'>İletişim Bilgilerimiz</span>
                                    <p className='mt-5 text-sm opacity-50'>Güncel iletişim bilgilerimiz aşağıda bulunmaktadır. Size her zaman en iyi hizmeti sunabilmek adına sorularınızı, görüşlerinizi veya taleplerinizi bekliyoruz.</p>

                                    <div className='flex items-center gap-4 text-white mt-9'>
                                        <ChangeSVG name='location' width={24} height={24} color='#fff' />
                                        <span className='w-2/4 mt-1 text-sm font-medium'>Abdiipekçi Mah. Ethempaşa Cad. 1533 Sok. No: 2 Darıca/ Kocaeli</span>
                                    </div>

                                    <a href="https://wa.me/+905076424763" className='flex items-center gap-4 text-white mt-9'>
                                        <ChangeSVG name='phone' width={24} height={24} color='#fff' />
                                        <span className='w-2/4 text-sm font-medium'>+90 0507 642 47 63 | 0 262 655 54 85</span>
                                    </a>

                                    <a href="/" className='flex items-center gap-4 text-white mt-9'>
                                        <ChangeSVG name='fax' width={24} height={24} color='#fff' />
                                        <span className='w-2/4 text-sm font-medium'>0262 655 51 05</span>
                                    </a>

                                    <div onClick={(e) => { window.location.href = 'mailto:info@guzeltepemetal.com'; }} className='flex items-center gap-4 text-white cursor-pointer mt-9'>
                                        <ChangeSVG name='envelope' width={24} height={24} color='#fff' />
                                        <span className='w-2/4 mb-2 text-sm font-medium'>info@guzeltepemetal.com</span>
                                    </div>

                                    <div className='w-3/4 mt-5'>
                                        <span className='text-sm opacity-50'>Bizimle iletişim kurmak için yandaki formu doldurabilir veya iletişim numaramızdan arayabilirsiniz.</span>
                                    </div>
                                </div>

                                <div className="absolute bottom-0 z-0 -top-16 end-0 start-0 opacity-10">
                                    <ChangeSVG name='gzltepelogo' width={124} height={124} color='fff' />
                                </div>
                            </div>
                        </div>
                        <div className="basis-6/12">
                            <div >
                                {/* <form onSubmit={sendEmail}>
                                    <div>
                                        <label htmlFor="names" className='font-bold'>Adınız ve Soyadınız</label>
                                        <div className='relative w-full mt-3 rounded-md'>
                                            <input className='w-full px-10 py-2 text-sm bg-white' type="names" id="names" value={name} placeholder='Adınız ve soyadınızı giriniz' onChange={handleNameChange} />
                                            <div className="absolute top-0 bottom-0 flex items-center opacity-50 left-3">
                                                <ChangeSVG name='user' width={14} height={14} color='#000' />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='mt-6'>
                                        <label htmlFor="email" className='font-bold'>E-posta Adresiniz</label>
                                        <div className='relative w-full mt-3 rounded-md'>
                                            <input className='w-full px-10 py-2 text-sm bg-white' type="email" id="email" value={email} placeholder='Adınız ve soyadınızı giriniz' onChange={handleEmailChange} />
                                            <div className="absolute top-0 bottom-0 flex items-center opacity-50 left-3">
                                                <ChangeSVG name='envelope' width={14} height={14} color='#000' />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='mt-6'>
                                        <label htmlFor="phone" className='font-bold'>Telefon Numarası</label>
                                        <div className='relative w-full mt-3 rounded-md'>
                                            <input className='w-full px-10 py-2 text-sm bg-white' type="text" id="phone" placeholder='Telefon numaranızı girinizAdınız ve soyadınızı giriniz' value={phone} onChange={handlePhoneChange} />
                                            <div className="absolute top-0 bottom-0 flex items-center opacity-50 left-3">
                                                <ChangeSVG name='phone' width={14} height={14} color='#000' />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='mt-6'>
                                        <label htmlFor="name" className='font-bold'>Bize İletmek İstediğiniz Mesajınız</label>
                                        <div className='w-full mt-3 rounded-md'>
                                            <textarea className='w-full p-2 text-sm resize-none' id="message" rows='3' placeholder='Bize iletmek istediğiniz konuyu yazınız' value={message} onChange={handleMessageChange} />
                                        </div>
                                    </div>

                                    <button className='py-2 bg-[#D22027] text-white text-sm px-5 rounded-md mt-3' type="submit">Send Email</button>
                                </form> */}
                                <form ref={form} onSubmit={sendEmail}>
                                    <div>
                                        <label htmlFor="from_name" className='font-bold'>Adınız ve Soyadınız</label>
                                        <div className='relative w-full mt-3 rounded-md'>
                                            <input className='w-full px-10 py-2 text-sm bg-white' type="from_name" id="from_name" name="from_name" placeholder='Adınız ve soyadınızı giriniz' />
                                            <div className="absolute top-0 bottom-0 flex items-center opacity-50 left-3">
                                                <ChangeSVG name='user' width={14} height={14} color='#000' />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='mt-6'>
                                        <label htmlFor="to_name" className='font-bold'>E-posta Adresiniz</label>
                                        <div className='relative w-full mt-3 rounded-md'>
                                            <input className='w-full px-10 py-2 text-sm bg-white' type="to_name" id="to_name" name="to_name" placeholder='Adınız ve soyadınızı giriniz' />
                                            <div className="absolute top-0 bottom-0 flex items-center opacity-50 left-3">
                                                <ChangeSVG name='envelope' width={14} height={14} color='#000' />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='mt-6'>
                                        <label htmlFor="from_phone" className='font-bold'>Telefon Numarası</label>
                                        <div className='relative w-full mt-3 rounded-md'>
                                            <input className='w-full px-10 py-2 text-sm bg-white' type="text" id="from_phone" name="from_phone" placeholder='Telefon numaranızı giriniz' />
                                            <div className="absolute top-0 bottom-0 flex items-center opacity-50 left-3">
                                                <ChangeSVG name='phone' width={14} height={14} color='#000' />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='mt-6'>
                                        <label htmlFor="message" className='font-bold'>Bize İletmek İstediğiniz Mesajınız</label>
                                        <div className='w-full mt-3 rounded-md'>
                                            <textarea className='w-full p-2 text-sm resize-none' id="message" name="message" rows='3' placeholder='Bize iletmek istediğiniz konuyu yazınız' />
                                        </div>
                                    </div>
                                    <button className='py-2 bg-[#D22027] text-white text-sm px-5 rounded-md mt-3' type="submit">Send Email</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </article>
            </section>

            <section className=''>
                <article className='container'>
                    <div className='flex flex-col items-center md:flex-row gap-9'>
                        <div className='basis-8/12'>
                            <h2 className='text-2xl font-black text-[#D22027]'>İhtiyaçlarınız için iletişime geçiniz</h2>
                            <p className='mt-8 text-sm'>En güncel fiyatlarımızı ve ihtiyaçlarınıza en uygun teklifleri öğrenmek için her zaman bize ulaşabilirsiniz. Profesyonel ekibimiz, ihtiyaçlarınızı karşılamak ve en uygun çözümleri sunmak için sizinle birlikte çalışmaktan memnuniyet duyacaktır. </p>
                            <p className='mt-8 text-sm font-black'>Size en iyi hizmeti sunmak için buradayız, geniş ürün yelpazemizden yararlanmak için bize ulaşın.</p>
                        </div>
                        <div className='basis-4/12'>
                            <div className='flex items-center justify-center px-10 py-10 bg-white'>
                                <div className='opacity-25'>
                                    <ChangeSVG name='phonemail' width={175} height={175} color='#000' />
                                </div>
                            </div>
                        </div>
                    </div>
                </article>
            </section>



            <section className="container py-10 overflow-hidden md:py-10">
                <iframe title="Google Haritası" className="w-full h-[25rem] rounded-md"
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d25076.541067346425!2d29.393340665658943!3d40.78397824332504!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cadf05a36e11ed%3A0x8e43010a33045dfa!2sG%C3%BCzeltepe%20metal%20mam%C3%BClleri!5e0!3m2!1str!2str!4v1710847097860!5m2!1str!2str"
                    allowFullScreen
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade">
                </iframe>

            </section>


        </main>
    )
}

export default Iletisim;