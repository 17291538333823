import { useParams } from 'react-router-dom';
import ProductData from '../assets/data/urunler';
import Modal from 'react-modal';

import navBackground from '../assets/images/urunlerimiz/urunlerimiz.png'
import ChangeIMG from '../helpers/ChangeIMG';
import ChangeSVG from '../helpers/ChangeSVG';
import SwiperSlider from '../components/SwiperSlider';
import { Navigation, Autoplay } from 'swiper/modules';
import { Helmet } from 'react-helmet';
import { useState } from 'react';

const customStyles = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        zIndex: 1000,
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        border: 'none',

    },
};

const DipUrunDetay = () => {

    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };


    const { category, subcategory, id, product } = useParams();

    const categoryData = ProductData.find(product => product.url === category);

    const products = categoryData.products.find(product => product.url === `${subcategory}/${id}`);

    const item = products.subcategory.find(subitem => subitem.url === `${product}/category`)






    return (
        <main>
            <Helmet>
                <title>{item.title}</title>
                <meta name="description" content={`${item.content}`} />
            </Helmet>

            <div className='relative'>
                <img className='object-cover w-full h-32' src={navBackground} alt="" />
                <div className="absolute top-0 bottom-0 bg-black bg-opacity-65 start-0 end-0"></div>
            </div>

            <section>
                <article className="container pt-16 pb-10">
                    <div className="relative">
                        <div className="flex flex-col md:flex-row">
                            <div className="basis-7/12">
                                <div className='p-5'>
                                    <ChangeIMG page={item.imagePage} name={item.image} alt={item.title} rounded='xl' />
                                </div>
                            </div>
                            <div className="basis-5/12">
                                <div className='pt-5 pl-6 md:pl-0'>
                                    <a className='flex items-center gap-3' href={`/urunlerimiz/${categoryData.category}`}>
                                        <div className='rotate-180'>
                                            <ChangeSVG name='arrow' width={16} height={16} />
                                        </div>
                                        <span className='text-sm font-bold'>Ürünü Grubuna Dön</span>
                                    </a>
                                    <h1 className='mt-6 text-2xl font-black'>{item.title}</h1>
                                    <p className='pr-20 mt-4 text-sm opacity-50'>{item.content}</p>
                                </div>
                                <div className='flex items-center gap-3'>
                                    <div className='pl-6 mt-10 mb-10 lg:mt-20 md:pl-0 lg:mb-0'>
                                        <a className='px-7 py-2 bg-[#D22027] text-white rounded-md font-medium' href="/iletisim">Teklif Alın</a>
                                    </div>
                                    {item.pdfName && (
                                        <div className='pl-6 mt-10 mb-10 lg:mt-20 md:pl-0 lg:mb-0'>
                                            <div className='px-7 py-2 border border-[#D22027] text-[#D22027] hover:bg-white duration-300  rounded-md font-medium' onClick={openModal}>Ölçüleri Görüntüle</div>
                                        </div>
                                    )}


                                </div>
                            </div>
                        </div>
                        <Modal style={customStyles} isOpen={isModalOpen} onRequestClose={closeModal}>
                            <div className="flex h-[40rem] mt-5">
                                <ChangeIMG page='olcumler' name={`${item.pdfName}`} />
                            </div>
                            <button className='px-6 py-2 bg-[#D22027] text-white font-bold text-xs rounded-md mt-3' onClick={closeModal}>KAPAT</button>
                        </Modal>

                        <div className="absolute top-0 start-0 end-0 -z-10">
                            <div className="bg-[#EDEDED] w-full h-[12rem] rounded-t-3xl"></div>
                        </div>
                    </div>
                </article>
            </section>

            <section>
                <article className='container'>
                    <h2 className='text-3xl font-semibold'>Ürün Özellikleri</h2>
                    <div className="bg-[#D22027] h-1 w-1/6 mt-4"></div>
                </article>
                <article className='container'>
                    <div className="p-10 mt-10 bg-white rounded-xl">
                        <p className=''>{item.subContent}</p>
                        <div className="flex flex-col gap-4 mt-10">
                            {
                                item.property.map((content, index) => (
                                    <div key={index}>
                                        <div className='flex items-center gap-3'>
                                            <ChangeSVG name='baklava' width={16} height={16} color='#D22027' />
                                            <span>{content}</span>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </article>
            </section>

            <section className='my-16'>
                <article className='container relative'>
                    <h3 className='text-2xl font-bold'>Ürün Grubundaki Diğer Ürünler</h3>
                    <div className="absolute z-10 text-white top-12 md:top-0 right-12">
                        <button className="-rotate-90 blog-next"> <ChangeSVG name="arrowdown" width={16} height={16} color="#fff" /></button>
                    </div>
                    <div className="absolute z-10 text-white top-12 md:top-0 right-24">
                        <button className="rotate-90 blog-prev"> <ChangeSVG name="arrowdown" width={16} height={16} color="#fff" /></button>
                    </div>
                </article>
                <article className='container'>
                    <div className='py-10'>
                        <SwiperSlider className="z-20 h-full"
                            modules={[Navigation, Autoplay]} prev=".blog-prev" next=".blog-next"
                            spaceBetween={36} slideView={3} loop={true}
                            initialSlide={1} delay={2000} speed={10000}
                            slides={
                                categoryData.products.map((product, index) => (
                                    <div className='' key={index}>
                                        {product.image && (
                                            <div className='p-5 bg-white border border-black border-opacity-10 rounded-xl'>
                                                <ChangeIMG page={product.imagePage} name={product.image} alt={product.title} rounded='xl' />
                                                <p className='mt-6 font-black'>{product.title}</p>
                                                <p className='mt-3 text-sm opacity-50 line-clamp-3'>{product.content}</p>
                                                <div className='flex justify-end mb-3 mt-9'>
                                                    <a className='flex items-center gap-3 text-sm text-[#D22027] font-bold' href={`/urunlerimiz/${categoryData.url}/${product.url}`}>
                                                        <span>Ürünü İnceleyin</span>
                                                        <ChangeSVG name='arrow' width={16} height={16} color='#D22027' />
                                                    </a>
                                                </div>
                                            </div>
                                        )}
                                        {!product.image && (
                                            <div className='p-3 bg-white border border-black border-opacity-10 rounded-xl'>
                                                <p className='font-black'>{product.title}</p>
                                            </div>
                                        )}
                                    </div>
                                ))
                            }
                        />
                    </div>
                </article>
            </section>

        </main>
    )
}

export default DipUrunDetay;