import ChangeIMG from "../helpers/ChangeIMG";

const HalfBannerData = [
    {
        title: 'HAKKIMIZDA',
        content: 'Güzeltepe Metal olarak, demir çelik sektöründe uzun yıllardan beri müşterilerine güvenilir, kaliteli ve çeşitli ürün çözümleri sunan bir şirketiz. Kurulduğumuz günden itibaren sektöre olan derin bağlılığımız ve müşteri memnuniyetini ön planda tutan yaklaşımımızla, sektörde kendimize sağlam bir yer edindik.',
        image: 'hakkimizdabanner',
        image_page: 'hakkimizda'
    },
    {
        title: 'ÜRÜNLERİNİZ',
        content: 'Güzeltepe Metal olarak, demir çelik sektöründe geniş bir ürün yelpazesi sunmaktayız. Sac, profil, boru, demir-çelik, levha, dekoratif ürünler, hırdavat çeşitleri gibi birçok ürünü içeren geniş ürün yelpazemiz, müşterilerimize çeşitli ihtiyaçlara uygun kaliteli çözümler sunmaktadır.',
        image: 'urunlerimiz',
        image_page: 'urunlerimiz'
    },
    {
        title: 'REFERANSLARIMIZ',
        content: 'Güzeltepe Metal olarak, müşteri memnuniyeti ve güvenilirlik ilkelerine sıkı sıkıya bağlıyız. İş ortaklarımızın memnuniyeti bizim için en önemli önceliktir ve bu nedenle referanslarımıza büyük önem veriyoruz. Güvenilir ve sağlam iş ortaklığımızla, referanslarımızın bizimle ilgili güvenilirlik ve kalite konusunda sağlam bir kanıt olduğuna inanıyoruz.',
        image: 'referanslar',
        image_page: 'referanslar'
    },
    {
        title: 'BLOGLARIMIZ',
        content: 'Güzeltepe Metal olarak, demir çelik sektöründe geniş bir ürün yelpazesi sunmaktayız. Sac, profil, boru, çelik levha, kesme taşları, elektrot, hırdavat çeşitleri, boya ve dekoratif demir gibi birçok ürünü içeren geniş ürün yelpazemiz, müşterilerimize çeşitli ihtiyaçlara uygun kaliteli çözümler sunmaktadır.',
        image: 'blog',
        image_page: 'blog'
    },
    {
        title: 'İLETİŞİM',
        content: 'Güzeltepe Metal olarak, siz değerli müşterilerimizle sağlamış olduğumuz iletişim kanallarıyla iletişime geçmekten ve ihtiyaçlarınızı karşılamaktan büyük bir memnuniyet duyuyoruz. Firmamız, endüstriyel çözümler alanında uzmanlaşmış ekibimizle kaliteli ürünler ve müşteri memnuniyeti odaklı hizmet anlayışıyla hizmet vermektedir.',
        image: 'iletisim',
        image_page: 'anasayfa'
    },
];

const HalfBanner = ({ page }) => {

    const data = HalfBannerData.find(item => item.title === page);

    if (!data) {
        return null;
    }

    const { title, content, image, image_page } = data;


    return (
        <section>
            <div className="relative">
                <ChangeIMG page={image_page} name={image} height={'banner'} />
                <div className="absolute top-0 bottom-0 text-white bg-black bg-opacity-65 start-0 end-0">
                    <div className="container flex items-center h-full">
                        <div className="flex flex-col lg:mt-28">
                            <h1 className="text-3xl font-black">{title}</h1>
                            <p className="opacity-75 mt-7">{content}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default HalfBanner;