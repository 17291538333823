
const blogData = [
    {
        title: 'Profil Türleri ve Kullanım Alanları',
        content: 'Profiller, endüstride ve inşaat sektöründe geniş bir kullanım alanına sahip olan çok yönlü yapı malzemeleridir. Farklı tipteki çelik profiller, çeşitli yapısal uygulamalarda kullanılır ve farklı avantajlar sunar.',
        image:'blog01',
        url:'profil-turleri',
        longContent: [
            {
                title:'H Profil ve I Profil',
                content:'H profil ve I profil, dikdörtgen kesitli çelik profillerdir ve yapısal dayanıklılık sağlamak için genellikle kullanılır. H profiller, geniş alanlarda yük taşıma kapasitesi gerektiren uygulamalarda tercih edilirken, I profiller daha çok çelik konstrüksiyon projelerinde, köprü ve kiriş yapılarında kullanılır.'
            },
            {
                title:'U Profil ve L Profil',
                content:'U profil düzgün kesitli çelik profillerdir ve genellikle çerçeve ve kasa yapımında kullanılır. U profiller, dikdörtgen kesitli bir açıklığa sahipken L profil, dikdörtgen kesitli çelik profillerdir ve genellikle bağlantı ve destek elemanları olarak kullanılır. '
            },
        ]
    },
    {
        title: 'Yapı Sektöründe Sandviç Panel',
        content: 'Sandviç paneller, yapı sektöründe hızlı ve verimli bir şekilde kullanılan inovatif yapı malzemeleridir. Bu paneller, üst ve alt tabakalar arasında izolasyon malzemesi bulunan bir çekirdekten oluşur ve birleşik yapıları ile birçok avantaj sunarlar.',
        image:'blog02',
        url:'sandvic-panel',
        longContent: [
            {
                title:'AVANTAJLARI',
                content:'Sandviç panellerin birçok avantajı vardır ve bunlar inşaat sektöründe tercih edilmelerinin ana nedenleridir. Yüksek İzolasyon Yeteneği: Sandviç paneller, yüksek kaliteli izolasyon malzemeleri ile dolu olan çekirdekleri sayesinde mükemmel bir ısı ve ses yalıtımı sağlarlar. Bu özellik, binaların enerji verimliliğini artırır ve iç mekanlarda daha konforlu bir yaşam ortamı sunar. Hafiflik ve Dayanıklılık: Sandviç paneller, hafif olmalarına rağmen son derece dayanıklıdırlar. Bu özellikleri, taşıyıcı yapıların mukavemetini artırırken inşaat süreçlerini hızlandırır ve maliyetleri düşürür.'
            },
            {
                title:'SONUÇ',
                content:'Sandviç paneller, yapı sektöründe çeşitli avantajlar sunan ve birçok farklı uygulamada kullanılan çok yönlü bir yapı malzemesidir. Yüksek izolasyon yetenekleri, hafif yapıları, hızlı montajları ve estetik çeşitlilikleri sayesinde, modern inşaat projelerinde tercih edilen bir seçenektirler.'
            },
        ]
    },
    {
        title: 'Hırdavat ve Demir-Çelik Bakımı',
        content: 'Hırdavat ve demir-çelik ürünler, evdeki küçük onarımlardan endüstriyel uygulamalara kadar geniş bir yelpazede kullanılan önemli malzemelerdir. Ancak, bu ürünlerin düzenli bakımı yapılmadığında performansı düşebilir ve ömrü kısalabilir.',
        image:'blog03',
        url:'hirdavat-ve-demir-celik-bakimi',
        longContent: [
            {
                title:'Temizlik ve Koruma',
                content:'Hırdavat ve demir-çelik ürünlerin bakımının ilk adımı, düzenli olarak temizlenmesi ve korunmasıdır. Kullanılan malzemeye bağlı olarak, ürünlerin toz, kir ve pas gibi kirleticilerden arındırılması önemlidir. Bunun için yumuşak bir bez veya fırça kullanarak düzenli aralıklarla temizlik yapılmalıdır.'
            },
            {
                title:'Doğru Depolama ve Kullanım',
                content:'Hırdavat ve demir-çelik ürünlerin uzun ömürlü kullanımı için doğru depolama ve kullanım önemlidir. Ürünlerin nemli ortamlardan uzak tutulması, doğrudan güneş ışığına maruz kalmaması ve aşırı sıcaklık değişimlerinden korunması gerekmektedir. Ayrıca, ürünlerin kullanım talimatlarına uygun şekilde kullanılması ve gerektiğinde uygun kişisel koruyucu ekipmanların kullanılması da önemlidir. Hırdavat ve demir-çelik ürünler, doğru bakım ve düzenli kontrol ile uzun ömürlü olarak kullanılabilirler. Temizlik, koruma, düzenli kontroller ve doğru depolama gibi basit önlemler, ürünlerin performansını artırırken kullanıcıların güvenliğini de sağlar. Profesyonel bakım ve servis hizmetlerinden de gerektiğinde faydalanılmalıdır, böylece ürünlerin uzun ömürlü kullanımı sağlanabilir.'
            },
        ]
    },
    {
        title: 'Dekorasyonda Dekoratif Demirler',
        content: 'Dekoratif demir, tasarım ve dekorasyon projelerinde sıklıkla kullanılan çok yönlü bir malzemedir. Estetik görünümü ve dayanıklılığıyla öne çıkan dekoratif demir, iç ve dış mekanlarda birçok farklı amaç için kullanılabilir.',
        image:'blog04',
        url:'dekoratif-demirler',
        longContent: [
            {
                title:'Merdiven Korkulukları ve Kapılar',
                content:'Ev içi tasarımında, dekoratif demir genellikle merdiven korkuluklarında ve kapılarda kullanılır. Özellikle antik tarzda dekorasyon yapanlar için tercih edilen bir malzemedir. El ya da makine işçiliği ile yapılan özel desenler ve detaylar, evin iç mekanına karakter katar ve zarafet sağlar.'
            },
            {
                title:'Dekorasyon ve Yenileme',
                content:'Ev dekorasyonunda, dekoratif demir çeşitli aksesuarlar ve dekoratif eşyaların yapımında kullanılabilir. Örneğin, mumluklar, duvar askıları, raflar ve dekoratif tablolar gibi birçok farklı eşyada dekoratif demir detayları bulunabilir. Eski binaların restorasyon ve yenileme projelerinde dekoratif demir sıkça kullanılır. Özellikle tarihi binaların orijinal detaylarını korumak ve eski tarzı korumak için tercih edilir. Restorasyon sürecinde kullanılan dekoratif demir, binaların orijinal görünümünü geri kazanmalarına yardımcı olur.'
            },
        ]
    },
    {
        title: 'Demir-Çelik Sektörü ve Teknoloji',
        content: 'Demir çelik sektörü, endüstriyel üretimden inşaat sektörüne kadar geniş bir yelpazede kullanılan temel malzemeleri sağlamak için önemli bir rol oynar. Bu sektördeki teknolojik gelişmeler, üretim verimliliğini artırırken çevresel sürdürülebilirliği ve ürün kalitesini iyileştirmeye yardımcı oluyor.',
        image:'blog05',
        url:'demir-celik-sektoru',
        longContent: [
            {
                title:'Akıllı Üretim ve Otomasyon',
                content:'Geleneksel üretim yöntemlerinden akıllı üretim sistemlerine geçiş, demir çelik endüstrisinde büyük bir dönüşüm sağlamaktadır. Sensörler, veri analizi ve yapay zeka gibi teknolojilerin entegrasyonu, üretim süreçlerini optimize ederken hata oranlarını azaltıyor ve enerji verimliliğini artırıyor.'
            },
            {
                title:'Dijital Tasarım ve Modelleme',
                content:'Dijital tasarım ve modelleme teknolojileri, ürün geliştirme süreçlerini hızlandırırken ürün kalitesini artırmaya yardımcı oluyor. Bilgisayar destekli tasarım (CAD) ve bilgisayar destekli mühendislik (CAE) yazılımları, üreticilere daha karmaşık ve yenilikçi ürünlerin tasarımını yapma imkanı veriyor. Ayrıca, bu teknolojiler sayesinde ürün prototipleri sanal ortamda test edilebiliyor ve optimize edilebiliyor, bu da maliyetleri düşürürken ürün kalitesini artırıyor. '
            },
        ]
    },
    {
        title: 'Metalleri Korozyona Karşı Koruma',
        content: 'Korozyon, metal malzemelerin yüzeyinde oluşan ve genellikle oksijen ile etkileşim sonucu meydana gelen bir kimyasal reaksiyon sürecidir. Bu süreç, çelik malzemelerin yapısını zayıflatır, dayanıklılığını azaltabilir ve kalitesini düşürebilir.',
        image:'blog06',
        url:'metal-korozyon-koruma',
        longContent: [
            {
                title:'Korozyonun Nedenleri',
                content:'Korozyonun birçok nedeni olabilir, ancak en yaygın nedenlerden bazıları su, nem, tuzlu ortamlar, asitler ve bazlar gibi kimyasal maddelerle temas, yüksek sıcaklık ve mekanik hasarlardır. Çelik malzemelerin korozyona karşı korunması için bu faktörlerin göz önünde bulundurulması önemlidir.',
            },
            {
                title:'Korozyona Karşı Korunma Yöntemleri',
                content:'Galvanizasyon: Çelik malzemelerin yüzeyine çinko kaplanarak korozyona karşı korunması sağlanır. Galvanizasyon, çelik malzemelerin dayanıklılığını artırır ve uzun ömürlü bir koruma sağlar. Boyama ve Kaplama: Çelik malzemelerin yüzeyine boya veya kaplama uygulanarak korozyona karşı korunması sağlanır. Bu yöntem, çelik malzemelerin estetik görünümünü korurken, aynı zamanda koruyucu bir tabaka oluşturur.'
            },
        ]
    },  
    
]

export default blogData;